import { TransferenceType } from '@autofact/operation-lib-model-notary-platform';

export default {
  codeToString(typeCode){
    const type = Object.values(TransferenceType).find(elementType => elementType.id === typeCode);
    if (!type) {
      return null;
    }

    return type.shortLabel;
  }
};
