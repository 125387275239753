import _ from 'lodash';
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';
import BackendApiService from '../../services/BackendApiService';
class ShowDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            details: []
        }
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.fetchData().then().catch(console.error);
    }

    async fetchData() {
        const details = await BackendApiService.getConsolidateDetails(this.props.item.consolidado_idconsolidado);
        if (details.length > 0) {
            this.setState({ loading: false, details: details });
        } else {
            this.setState({ loading: false, details: [] });
        }
    }

    getDetails = async (e) => {
        const detailCompacted = await BackendApiService.getConsolidateDetailsZip(this.props.item.consolidado_idconsolidado);
        if (detailCompacted) {
            window.location = detailCompacted.url
        } else {
            alert("Ocurrio un error al descargar los detalles");
        }
    }

    closeDialog = () => {
        if (typeof this.props.closeDialog === 'function') {
            this.props.closeDialog();
        }
    }

    renderUrl = (item) => {
        console.log("hola")
        return item.url_document ? <a href={item.url_document} target="blank">Ver</a> : '-';
    }

    renderDetail = () => {
        let itemsMapped = this.state.details.map((item, index) => {
            return <tr key={index}>
                <td>{_.startCase(_.toLower(item.consolidate_type))}</td>
                <td>{this.renderUrl(item)}</td>
            </tr>
        });

        if (itemsMapped.length === 0) {
            itemsMapped = <tr key={0}>
                <td colSpan={2} className='text-center'> <strong>No existen detalles </strong></td>
            </tr>;
        }
        return (<Row className="pt-3 pb-3">
            <Col sm={12}>
                <table className="table table-striped table-bordered">
                    <thead style={{ color: "grey" }}>
                        <tr>
                            <td>Tipo</td>
                            <td>Ver</td>
                        </tr>
                    </thead>
                    <tbody color="grey">
                        {itemsMapped}
                    </tbody>
                </table>
            </Col>
        </Row>);
    }

    render() {
        return (
            <Modal isOpen={true} className={this.props.className}>
                <ModalHeader className="bg-155892">
                    <label className="header-label font-weight-bold m-0">{this.props.title || 'Detalles'}</label>
                    <button type="button" className="close" aria-label="Close" onClick={this.props.closeDialog}>
                        <span aria-hidden="true">×</span>
                    </button>
                </ModalHeader>
                <ModalBody>
                    <div>
                        {this.state.loading ? 'Cargando...' : this.renderDetail()}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button disabled={this.state.details.length === 0} key="detail" className={`btn btn-sm btn-outline-detail text-center`} onClick={this.getDetails}>Descargar todo</button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ShowDetails;
