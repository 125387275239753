/**
 * Created by Ale on 7/17/2019.
 */
import BackendApiService from '../services/BackendApiService';
import {configVars} from '../config';

class PurchaseOrderHelper {

  static buildFilePath(params) {
    const { invoiceCenterCountryCode, invoiceCenterId } = params;
    return `${configVars.s3BucketOC}/${invoiceCenterCountryCode}/${invoiceCenterId}`;
  }

  static async uploadToS3(params) {
    return await BackendApiService.uploadPurchaseOrder(params);
  }

}

export default PurchaseOrderHelper;