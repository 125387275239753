import React, { Component } from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';

import DataTable from '../data-table/DataTable';
import moneyFormat from '../../helpers/MoneyFormat'
import InvoiceCenterClosureStatus from '../../services/InvoiceCenterClosureStatus';
import NotifiedPayment from '../../components/notified-payment/NotifiedPayment';
import BackendApiService from '../../services/BackendApiService';
import ShowDetails from '../../components/details/ShowDetails';
import CustomTooltip from '../../helpers/Tooltip';
class InvoiceCenterClosureList extends Component {
    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();

        this.state = {
            modalNotifiedPaymentIsOpen: false,
            closuresSelected: {},
            sendingNotification: false,
            showDetails: false,
            currentItem: {},
        }
    }

    reloadData() {
        this.dataTableRef.current.reloadData();
    }


    closeModalDetails = () => {
        this.setState({ showDetails: false });
    }

    async showModalDetails(e, item) {
        e.preventDefault();
        this.setState({ showDetails: true, currentItem: item });
    }

    renderDate = (item, key) => {
        if (item.hasOwnProperty(key) && item[key]) {
            const _date = moment(item[key]);
            if (_date.isValid()) {
                return <span className={'table-text'}>{_date.format("DD-MM-YYYY")}</span>
            } else {
                return '-';
            }
        } else {
            return '-';
        }
    }
    renderClosureType(item) {
        let classes = item.type === 'TRANSFERENCIA' ? 'bg-primary-darker text-white' : 'bg-primary-dark text-white';
        return <span className={`${classes} closure-type`}>{item.type}</span>
    }

    renderInvoiceCenter(item) {
        const invoiceCenter = item.invoice_center || {};
        const receiver = invoiceCenter.receiver || {};
        const invoiceCenterName = receiver.shortName || '';
        return invoiceCenterName
    }
    renderClosureStatus(item) {
        let classes = '';
        if (item.status === InvoiceCenterClosureStatus.PENDING) {
            classes = 'bg-pending'
        } else if (item.status === InvoiceCenterClosureStatus.LATE) {
            classes = 'bg-red text-white'
        } else if (item.status === InvoiceCenterClosureStatus.PAID || item.status === InvoiceCenterClosureStatus.TO_BE_CONFIRMED) {
            classes = 'bg-paid'
        }
        return <span className={`${classes} closure-status`}>{InvoiceCenterClosureStatus.getName(item.status)}</span>
    }

    // notified payment
    renderCheckBox(item) {
        return <input type="checkbox" className="form-control-sm" style={{ width: 17 }}
            onChange={(e) => this.onChangeChecked(e, item)} value={item.id}
            checked={this.state.closuresSelected.hasOwnProperty(item.id)}>
        </input>
    }
    onChangeChecked = (e, item) => {
        const closuresSelected = Object.assign({}, this.state.closuresSelected);
        if (e.target.checked) {
            if ([InvoiceCenterClosureStatus.PENDING, InvoiceCenterClosureStatus.LATE].includes(item.status)) {
                closuresSelected[item.id] = item;
            }
        } else {
            delete closuresSelected[item.id];
        }
        this.setState({ closuresSelected });
    }
    openNotifiedPaymentModal = (e) => {
        e.preventDefault();
        this.setState({ modalNotifiedPaymentIsOpen: true });
    }
    closeModalNotifiedPayment = () => {
        this.setState({ modalNotifiedPaymentIsOpen: false });
    }
    notifiedPayment = async (formData) => {
        try {
            this.setState({ sendingNotification: true });
            const closuresIds = Object.keys(this.state.closuresSelected).map(id => parseInt(id));
            const changeStatusParams = { closuresIds, status: InvoiceCenterClosureStatus.TO_BE_CONFIRMED };
            // change selected closures status
            const changeStatusResponse = await BackendApiService.changeClosuresStatus(changeStatusParams);
            if (changeStatusResponse.affectedRows === closuresIds.length) {
                const sendNotificationResponse = await this._sendEmailPaymentNotification(formData);
                if (sendNotificationResponse) {
                    this.setState({ modalNotifiedPaymentIsOpen: false, sendingNotification: false, closuresSelected: {} })
                    alert('Fue enviada la notificación de pago satisfactoriamente');
                    this.reloadData();
                    return true;
                }
            }

            alert('Ocurrió un error enviando la notificación de pago');
            this.setState({ sendingNotification: false })
        } catch (error) {
            console.error(error);
            alert('Ocurrió un error enviando la notificación de pago');
        }
    }
    /**
    * Send email about payment notification
    * @param formData payment form data
    */
    _sendEmailPaymentNotification = async (formData) => {
        const { paymentDate, paymentBankAccount, amount } = formData;
        const closuresSelected = Object.values(this.state.closuresSelected);
        const socialReason = closuresSelected[0].invoice_center.receiver.name || '';
        let sumTotals = 0;
        const closuresData = closuresSelected.map(closure => {
            sumTotals += closure.total_amount;
            const mapped = {};
            mapped.correlative = closure.correlative;
            mapped.icName = closure.invoice_center.receiver.shortName || '';
            mapped.status = 'POR CONFIRMAR';
            mapped.sendDate = closure.send_date;
            mapped.expirationDate = closure.expiration_date;
            mapped.totalClosure = `$${moneyFormat(closure.total_amount)}`;
            return mapped;
        })
        const beautifyAmount = `$${moneyFormat(amount.replace(/\./g, ''))}`;
        const emailNotificationParams = {
            paymentDate, paymentBankAccount, amount: beautifyAmount, closuresData, socialReason,
            sumTotals: `$${moneyFormat(sumTotals)}`
        }
        return await BackendApiService.sendEmailPaymentNotificationClosure(emailNotificationParams);
    }

    render() {
        const columns = [
            {
                Header: '',
                width: 50,
                Cell: row => {
                    return this.renderCheckBox(row.original)
                }
            },
            {
                Header: 'Correlativo',
                accessor: 'correlative',
                headerClassName: 'wordwrap',
                className: `table-text text-center`
            },
            {
                Header: 'Tipo',
                accessor: 'type',
                headerClassName: 'wordwrap',
                Cell: row => {
                    return this.renderClosureType(row.original)
                }
            }, {
                Header: 'Centro de facturación',
                accessor: 'type',
                headerClassName: 'wordwrap',
                Cell: row => {
                    return this.renderInvoiceCenter(row.original)
                }
            },
            {
                Header: 'Fecha envío cierre',
                accessor: 'send_date',
                headerClassName: 'wordwrap',
                Cell: row => {
                    return this.renderDate(row.original, 'send_date')
                }
            }, {
                Header: 'Fecha de vencimiento',
                accessor: 'expiration_date',
                headerClassName: 'wordwrap',
                Cell: row => {
                    return this.renderDate(row.original, 'expiration_date')
                }
            },
            {
                Header: 'Valor',
                accessor: 'total_amount',
                headerClassName: 'wordwrap',
                Cell: row => {
                    return <span className={`table-text text-center`}>${moneyFormat(row.original.total_amount)}</span>
                }
            },
            {
                Header: 'Saldo',
                accessor: 'paid_amount',
                headerClassName: 'wordwrap',
                Cell: row => {
                    return <span className={`table-text text-center`}>${moneyFormat(row.original.paid_amount)}</span>
                }
            },
            {
                Header: 'Estado',
                accessor: 'status',
                headerClassName: 'wordwrap',
                Cell: row => {
                    return this.renderClosureStatus(row.original)
                }
            },
            {
                Header: 'Ver',
                accessor: 'status',
                headerClassName: 'wordwrap',
                Cell: row => {
                    if (row.original.url_detail) {
                        return <button key="detail" className={`btn btn-sm btn-outline-detail`} onClick={(e) => { this.showModalDetails(e, row.original) }}>VER DETALLE</button>
                    }
                    return null;
                }
            }
        ];
        return (
            <div className={`invoice-center-closure-list-wrapper tab-pane`}>
                {this.state.showDetails && <ShowDetails
                    item={this.state.currentItem}
                    className='cobranza-modal draft'
                    closeDialog={this.closeModalDetails}
                />}
                {
                    this.state.modalNotifiedPaymentIsOpen &&
                    <NotifiedPayment
                        closeModalHandler={this.closeModalNotifiedPayment}
                        notifiedPaymentHandler={this.notifiedPayment}
                        loading={this.state.sendingNotification}
                    />
                }
                <div className="invoice-center-closure content-buttons">
                    <CustomTooltip content="Selecciona los cierres que deseas notificar" placement="bottom">
                        <span className="notifyButton">
                            <Button disabled={Object.keys(this.state.closuresSelected).length === 0}
                                style={Object.keys(this.state.closuresSelected).length === 0 ? { pointerEvents: "none" } : {}}
                                onClick={this.openNotifiedPaymentModal}
                                className="btn btn-sm btn-primary-dark btn btn-secondary btn-notified-payment closures">
                                Notificar pago
                            </Button>
                        </span>
                    </CustomTooltip>
                </div>
                <hr></hr>
                <DataTable
                    ref={this.dataTableRef}
                    key={`data-table-${this.props.id}`}
                    id={this.props.id}
                    columns={columns} {...this.props}
                />
                <label>* PAGADO * : Comprobante de pago recibido, pendiente de revisión en Banco.</label>
            </div>
        );
    }
}

export default InvoiceCenterClosureList;
