const configJson = require('./config-imported');

const payment = {
    invoice: {
        successUrl: configJson.INVOICE_PAYMENT_SUCCESS_URL || process.env.INVOICE_PAYMENT_SUCCESS_URL ||  'https://cobranza.pro.autofact.qa/facturas/pago-exitoso',
        errorUrl: configJson.INVOICE_PAYMENT_ERROR_URL || process.env.INVOICE_PAYMENT_ERROR_URL ||  'https://cobranza.pro.autofact.qa/facturas/pago-error',
        paymentUrl: configJson.INVOICE_PAYMENT_URL || process.env.INVOICE_PAYMENT_URL ||  'https://pagos.autofact.qa/pay-documents',
        defaultPaymentType: configJson.INVOICE_DEFAULT_PAYMENT_TYPE || process.env.INVOICE_DEFAULT_PAYMENT_TYPE || '41',
        totalLimit: Number(configJson.MAX_AMOUNT_TO_PAY_CL || process.env.MAX_AMOUNT_TO_PAY_CL || '5000000')
    }
}

const endpoints = {
    backendApi: {
        url: configJson.REACT_APP_BACKEND_API_URL || process.env.REACT_APP_BACKEND_API_URL ||  'https://api.cobranza.pro.autofact.qa/'
    },
    autofactProApi: {
        url: configJson.REACT_APP_LOGIN_PRO_URL || process.env.REACT_APP_LOGIN_PRO_URL || 'https://pro.autofact.qa/?callback=https://cobranza.pro.autofact.qa/login',
        base: configJson.REACT_APP_PRO_URL || process.env.REACT_APP_PRO_URL || 'http://pro.autofact.qa/',
        domain: configJson.REACT_APP_PRO_DOMAIN ||process.env.REACT_APP_PRO_DOMAIN || '.pro.autofact.qa',
    }
};

const s3BucketOC = process.env.S3_BUCKET_OC || "doc.operaciones.autofactpro.com/QA";
const showOCDateAfter = process.env.SHOW_OC_DATE_AFTER || "2018-09-01";
const configVars = {
  formats: { date: 'D-MM-YYYY LTS' },
  s3BucketOC,
  showOCDateAfter
}

//Development .env.local
//Production .env.production

module.exports = {
    endpoints,
    configVars,
    payment
};
