/*
 * Agregar logger en applyMiddleware para debuggear
 * */

//midlewares
import {createStore, combineReducers, applyMiddleware} from "redux";
//reducers general app
import logger from "redux-logger";

import app from "./reducers/app";

//Combine the reducers/blog into the one store
const middleware = applyMiddleware(logger);


const reducers = combineReducers({
    app
});

const store = createStore(reducers, middleware);

export default store;
