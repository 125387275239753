import React, {Component} from 'react';
import BackendApiService from "../../services/BackendApiService";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input,Col} from 'reactstrap';
import {changeClosureCount} from '../../store/dispatchers/app'

import InvoiceCenterClosureList from "../invoice-center-closure-list/InvoiceCenterClosureList";

class InvoiceCenterClosureListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoiceCenterClosureList: [],
            params: this.props.filters || {},
            exporting: false,
            in_pay: false,
            closure:'',
            pay_in_process: false,
            pay_date: '',
            pay_amount:''
        };
        this.fetchData                        = this.fetchData.bind(this);
        this.invoiceCenterClosureComponentRef = React.createRef();
        this.showPayModal                     = this.showPayModal.bind(this);
        this.hidePayModal                     = this.hidePayModal.bind(this);
        this.confirmPay                       = this.confirmPay.bind(this);
    }

    async fetchData(params) {
        params = Object.assign(this.state.params, params);
        const invoiceCenterClosureList = await BackendApiService.getInvoiceCenterClosureList(params);
        changeClosureCount(invoiceCenterClosureList.extra.recordsTotal);
        this.setState({params, invoiceCenterClosureList});
        return invoiceCenterClosureList;
    }

    refreshFilters(filters) {
        this.setState({params: filters});
        setTimeout(() => {
            this.invoiceCenterClosureComponentRef.current.reloadData();
        }, 100);
    }

    handleFieldChange = (property, e)=> {
        this.setState({[property]: e.target.value});
    };

    showPayModal(item){
        this.setState({in_pay: true, closure:item});
    }
    hidePayModal(){
        this.setState({in_pay: false, closure: '', pay_date:'', pay_amount:'' ,pay_in_process: false})
    }

    async confirmPay(){
        this.setState({pay_in_process: true});
        const params = {
            paid_amount: this.state.pay_amount,
            pay_date: this.state.pay_date,
        };
        try{
            const payResponse = await BackendApiService.payInvoiceCenterClosure(this.state.closure.id, params);
            if(payResponse !== false){
                this.invoiceCenterClosureComponentRef.current.reloadData();
            }else{
                console.log("Ocurrio un error");
            }
            
        }catch (e){
            console.log(e);
        }
        this.hidePayModal();
    }
    renderModal(){
        return  <Modal isOpen={this.state.in_pay} toggle={this.toggle} className={this.props.className}>
            <ModalHeader toggle={this.toggle}>Pagar cierre</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup row>
                        <Label for="ammount" sm={12}>Monto</Label>
                        <Col sm={12}>
                            <Input type="number" name="amount" id="ammount" onChange={(e)=>this.handleFieldChange('pay_amount', e)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="paydate" sm={12}>Fecha de pago</Label>
                        <Col sm={12}>
                            <Input type="date" name="paydate" id="paydate" onChange={(e)=>this.handleFieldChange('pay_date', e)}/>
                        </Col>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="danger"  disabled={this.state.pay_in_process} onClick={this.hidePayModal}>Cerrar</Button>
                <Button color="primary" disabled={this.state.pay_in_process || !this.state.pay_date || !this.state.pay_amount} onClick={this.confirmPay}>Pagar</Button>{' '}
            </ModalFooter>
        </Modal>
    }
    render() {
        return (
            <div>
                <InvoiceCenterClosureList
                    ref={this.invoiceCenterClosureComponentRef}
                    id={this.props.id}
                    fetchDataCallback={this.fetchData}
                    payHandler={this.showPayModal}
                />
                {this.state.in_pay && this.renderModal()}
            </div>
        );
    }
}

export default InvoiceCenterClosureListContainer;
