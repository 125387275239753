/**
 * Created by Ale on 7/25/2019.
 */
import React, { Component } from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Col,
  FormGroup, Form, Input, Label, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import { configVars } from '../../config';
import BackendApiService from "../../services/BackendApiService";
class NotifiedPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        paymentDate: '',
        paymentBankAccount: '',
        paymentType: '',
        amount: '',
        comments: '',
        fileName: '',
        voucherUrl: '',
      },
      loadingFile: false,
    }
  }

  notifiedPayment = () => {
    this.props.notifiedPaymentHandler(this.state.formData);
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  uploadVoucher = async (file) => {
    let s3Url = '';
    try {
      const timestamp = moment().toDate().getTime();
      if (file) {
        this.setState({ loadingFile: true });
        const path = `${configVars.s3BucketOC}/vouchers-notified/${moment().format('YYYY-MM')}/`;
        const base64File = await this.getBase64(file);
        const fileName = `${timestamp}_${file.name}`;
        const uploadParams = {
          path, fileName: fileName, fileDataBase64: base64File
        }
        s3Url = await BackendApiService.uploadToS3(uploadParams);
      }
    } catch (e) {
      console.error(e);
    }

    return s3Url;


  };

  isValidForm = () => {
    return this.state.formData.paymentDate && this.state.formData.paymentBankAccount && this.state.formData.paymentType &&
      this.isValidAmount(this.state.formData.amount) && this.state.formData.comments && !this.state.loadingFile;
  }
  isValidAmount = (amount) => {
    const regexp = /^[\d.]+$/g;
    return regexp.test(amount);
  }
  onChangeForm = (e) => {
    let formData = Object.assign({}, this.state.formData);
    const value = e.target.value;
    const name = e.target.name || e.target.id;
    formData[name] = value;
    this.setState({ formData });
  }

  handleInputFileChange = async (e) => {
    let formData = Object.assign({}, this.state.formData);
    const files = e.target.files || [];
    const file = files[0] || {};
    formData.voucherUrl = await this.uploadVoucher(file);
    formData.fileName = file.name;
    this.setState({ formData, loadingFile: false });
  }

  render() {
    return (
      <Modal isOpen={true} size="md" centered={true} className="cobranza-modal">
        <ModalHeader className="bg-155892">
          <label className="header-label font-weight-bold m-0">Notificar pago</label>
          <button type="button" className="close" aria-label="Close"
            onClick={this.props.closeModalHandler} disabled={this.props.loading}>
            <span aria-hidden="true">×</span>
          </button>
        </ModalHeader>

        <ModalBody>
          <Form className="pl-sm-2 pr-sm-2">
            <FormGroup row>
              <Label for="paymentDate" className="body-label" sm={6}>Fecha de pago/depósito/transferencia</Label>
              <Col sm={6} className="justify-content-center align-items-center d-flex">
                <InputGroup>
                  <Input type="date" name="paymentDate" id="paymentDate" className="form-control-sm"
                    onChange={this.onChangeForm}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <FontAwesomeIcon icon="calendar-alt" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="paymentBankAccount" className="body-label" sm={6}>Cuenta Bancaria Autofact</Label>
              <Col sm={6}>
                <Input type="select" id="paymentBankAccount" className="form-control-sm"
                  onChange={this.onChangeForm}>
                  <option value="">-- Seleccione --</option>
                  <option value="14733876">Bice - 14733876 (Facturas)</option>
                  <option value="70463875">BCI  - 70463875 (Limpieza Multas)</option>
                  <option value="70823421">BCI  - 70823421 (Cierres Transferencias)</option>
                </Input>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="paymentType" className="body-label" sm={6}>Tipo de pago</Label>
              <Col sm={6}>
                <Input type="select" id="paymentType" className="form-control-sm"
                  onChange={this.onChangeForm}>
                  <option value="">-- Seleccione --</option>
                  <option value="Transferencia">Transferencia</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Depósito">Depósito</option>
                </Input>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="amount" className="body-label" sm={6}>Monto de pago/depósito/transferencia</Label>
              <Col sm={6} className="justify-content-center align-items-center d-flex">
                <Input type="text" name="amount" className="form-control-sm body-field"
                  onChange={this.onChangeForm}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="comments" className="body-label" sm={6}>Comentarios</Label>
              <Col sm={6} className="justify-content-center align-items-center d-flex">
                <Input type="textarea" name="comments" className="form-control-sm body-field"
                  onChange={this.onChangeForm}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={12} >
                <InputGroup>
                  <Input type="text" className="form-control disabled-input"
                    disabled placeholder="Adjuntar comprobante"
                    name="fileName" value={this.state.formData.fileName}
                  />
                  <span className="input-group-btn">
                    <input
                      type="file"
                      accept=".pdf" //image/*
                      style={{ display: 'none' }}
                      name="receiptFile"
                      id="receiptFile"
                      onChange={(e) => { this.handleInputFileChange(e) }}
                    />
                    <label htmlFor="receiptFile" className="btn btn-sm btn-input-upload">
                      <FontAwesomeIcon icon="upload" style={{ marginRight: 10 }} />
                      Subir
                    </label>
                  </span>
                </InputGroup>
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter style={{ justifyContent: 'center' }}>
          <button className="btn btn-md btn-actions btn-bg-white-orange mr-4"
            onClick={this.props.closeModalHandler} disabled={this.props.loading}>
            CANCELAR
          </button>
          <button className="btn btn-md btn-actions btn-bg-orange-white"
            style={{ display: this.props.loading ? 'none' : 'inline-block' }}
            onClick={this.notifiedPayment} disabled={!this.isValidForm()}>
            ACEPTAR
          </button>
          <div style={{ display: !this.props.loading ? 'none' : 'block' }} className="notified-loading-div">
            <div className="spinner-grow spinner-sm" hidden={!this.props.loading}></div>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

export default NotifiedPayment;