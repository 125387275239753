/**
 * Created by Ale on 7/11/2019.
 */
import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Alert,
  FormGroup, Form, Input, Label, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class UploadOC extends Component {
  constructor (props) {
    super(props);
    this.state = {
      formData: {
        ocNumber: '',
        ocDate: '',
        ocAmount: '',
        ocFile: '',
        ocFileName: ''
      },      
      remainingAmount: this.props.paymentDocRelated.total_sin_impuesto - this.props.paymentDocRelated.suma_total_orden_compra || 0,
      docRelatedOCNumbers: this.props.paymentDocRelated.numeros_orden_compra ? this.props.paymentDocRelated.numeros_orden_compra.split(',') : []
    }


  }

  uploadOC = () => {
    this.props.uploadOCHandler(this.state.formData);
  }
  isValidForm = () => {
    const regexp = /^[\w.-]+$/g;
    const stateData = this.state.formData;
    if (stateData.ocNumber && this.state.docRelatedOCNumbers.includes(`801-${stateData.ocNumber}`)) {
      alert('Ya existe una OC con este número');
      return false;
    } else {
      return stateData.ocNumber && regexp.test(stateData.ocNumber) && stateData.ocDate &&
        stateData.ocAmount && this.isValidAmount(stateData.ocAmount);
    }
  }
  onChangeForm = (e) => {
    let formData = Object.assign({}, this.state.formData);
    const value = e.target.value;
    const name = e.target.name;
    formData[name] = value;
    this.setState({formData});
  }
  handleInputFileChange = (e) => {
    let formData = Object.assign({}, this.state.formData);
    const files = e.target.files || [];
    const file = files[0] || {};
    const name = file.name || '';
    formData.ocFile = file; formData.ocFileName = name;
    this.setState({formData});
  }
  showMessageOCAmount = () => {
    const ocAmount = this.state.formData.ocAmount;
    if (ocAmount) {
      return this.props.paymentDocRelated.total_sin_impuesto !== this.props.paymentDocRelated.suma_total_orden_compra + parseInt(ocAmount);
    }
    return this.props.paymentDocRelated.total_sin_impuesto !== this.props.paymentDocRelated.suma_total_orden_compra;
  }
  isValidAmount = (amount) => {
    const regexp = /^[\d.]+$/g;
    return regexp.test(amount) && amount <= this.state.remainingAmount;
  }

  render() {
    return (
      <Modal isOpen={true} centered={true} className="cobranza-modal">
        <ModalHeader className="bg-155892">
          <FontAwesomeIcon icon="upload" className="header-icon" alt="Icono subir archivo"/>
          <label className="header-label font-weight-bold m-0">Subir Orden de Compra</label>
          <button type="button" className="close" aria-label="Close"
                  onClick={this.props.closeModalHandler} disabled={this.props.loading}>
            <span aria-hidden="true">×</span>
          </button>
        </ModalHeader>

        <ModalBody>
          {
            this.showMessageOCAmount() ?
              <Alert color="warning">
                El monto de la OC no calza (faltan {this.state.remainingAmount}).
              </Alert> : ''
          }

          <label className="body-title mb-3">
            Indica el número de Orden de Compra y a continuación adjunta el archivo. Para terminar presiona en "Continuar".
          </label>

          <Form className="pl-sm-5 pr-sm-5">
            <FormGroup row>
              <Label for="ocNumber" className="body-label" sm={6}>Número de Orden de Compra</Label>
              <Col sm={6}>
                <Input type="text" name="ocNumber"
                       className="form-control-sm body-field"
                       value={this.state.formData.ocNumber}
                       onChange={this.onChangeForm}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="ocDate" className="body-label" sm={6}>Fecha de Orden de Compra</Label>
              <Col sm={6}>
                <InputGroup>
                  <Input type="date" name="ocDate" id="ocDate" className="form-control-sm"
                        onChange={this.onChangeForm} value={this.state.formData.ocDate}/>
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <FontAwesomeIcon icon="calendar-alt" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="ocAmount" className="body-label" sm={6}>Monto</Label>
              <Col sm={6}>
                <Input type="text" name="ocAmount"
                       className="form-control-sm body-field"
                       value={this.state.formData.ocAmount}
                       onChange={this.onChangeForm}
                />
              </Col>
            </FormGroup>

            <FormGroup row style={{alignItems: 'center'}}>
              <Col sm={1} className="pr-0">
                <FontAwesomeIcon icon="paperclip" color="#909090" className="fa-rotate-90 fa-flip-vertical fa-md" />
              </Col>
              <Col sm={11} className="pl-0">
                <InputGroup>
                  <Input type="text" className="form-control disabled-input"
                         disabled placeholder="Adjuntar documento"
                         name="ocFileName" value={this.state.formData.ocFileName}
                  />
                  <span className="input-group-btn">
                    <input
                      type="file"
                      accept=".pdf" //image/*
                      style={{display:'none'}}
                      name="ocFile"
                      id="ocFile"
                      onChange={(e)=>{this.handleInputFileChange(e)}}
                    />
                    <label htmlFor="ocFile" className="btn btn-sm btn-input-upload">
                      <FontAwesomeIcon icon="upload" style={{marginRight: 10}}/>
                      Subir
                    </label>
                  </span>
                </InputGroup>
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter style={{justifyContent: 'center'}}>
          <button className="btn btn-md btn-actions btn-bg-white-orange mr-4"
                  onClick={this.props.closeModalHandler} disabled={this.props.loading}>
            CANCELAR
          </button>
          <button className="btn btn-md btn-actions btn-bg-orange-white"
                  style={{display: this.props.loading ? 'none' : 'inline-block'}}
                  onClick={this.uploadOC} disabled={!this.isValidForm()}>
            CONTINUAR
          </button>
          <div style={{display: !this.props.loading ? 'none' : 'block'}} className="notified-loading-div">
            <div className="spinner-grow spinner-sm" hidden={!this.props.loading}></div>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

export default UploadOC;