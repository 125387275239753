import constants from "../constants";

const data = {
    billingCount: 0,
    closureCount: 0,
    saleNotesCount: 0,
    userData:{}
};

export default function app(state = data, action) {
    if (action.type === constants.general.changeBillingCount)
        return {
            ...state,
            billingCount: action.billingCount
        };

    if (action.type === constants.general.changeClosureCount)
        return {
            ...state,
            closureCount:action.closureCount
        };

    if (action.type === constants.general.changeSaleNotesCount) {
      return {
        ...state,
        saleNotesCount: action.saleNotesCount
      };
    }
    
    if (action.type === constants.general.changeUserData)
        return {
            ...state,
            userData:action.userData
        };

    
    return state;
   
}
