import React, {Component} from 'react';
import InvoiceCenterContactList from '../invoice-center-contact-list/InvoiceCenterContactList';
import BackendApiService from "../../services/BackendApiService";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input,Col, Alert} from 'reactstrap';
import url from 'url'
import { split, replace, groupBy } from 'lodash';
import { endpoints } from '../../config';
import moment from 'moment';

class InvoiceCenterContactListContainer extends Component {
    constructor(props) {
        super(props);
        const isModalInfoOpen = url.parse(props.location.search, true).query.showMessage === 'true' ? true : false;

        this.state = {
            userData:this.props.userData,
            invoiceCenterList:[],
            params: this.props.filters || {},
            isModalOpen: false,
            isModalInfoOpen: isModalInfoOpen,
            isModalUpdateContactOpen: false,
            idInvoiceCenter: null,
            formData:{},
            formValid: false,
            emailValid: true,
            telefonoValid: true,
            nombreValid: true,
            cargoValid: true,
            isLastSaved: false,
            updatingData: false,
            contactData: []
        };

        this.fetchData = this.fetchData.bind(this);
        this.showUpdateModal = this.showUpdateModal.bind(this);
        this.hideUpdateModal = this.hideUpdateModal.bind(this);
        this.renderUpdateModal = this.renderUpdateModal.bind(this);
        this.handleChangeContactData = this.handleChangeContactData.bind(this);
        this.setFormStatus = this.setFormStatus.bind(this);
        this.isValidEmail = this.isValidEmail.bind(this);
        this.isValidTelefono = this.isValidTelefono.bind(this);
        this.updateInvoiceCenter = this.updateInvoiceCenter.bind(this);
        
        this.InvoiceCenterContactListContainerRef = React.createRef();
    }

    async fetchData(params){
        params = Object.assign(this.state.params, params)

        const allCompaniesPermission = Object.values(this.state.userData.usuario.permisos_uri).includes('aas:cobranza.update-invoice-center-contacts-in-company');

        let invoiceCenterList = {}

        if (allCompaniesPermission) {
            invoiceCenterList = await BackendApiService.getInvoiceCentersExtraData(this.state.userData.afpr_us_data.idgrupo_empresa, true);
        } else {
            invoiceCenterList = await BackendApiService.getInvoiceCentersExtraData(this.state.userData.afpr_us_data.id_empresa, false);
        }
        
        invoiceCenterList.extra = {
            recordsTotal: invoiceCenterList.data.length,
            recordsFiltered: 0
        }

        if(invoiceCenterList.data){
            var isAnyNoUpdated = invoiceCenterList.data.some(row => {
                return row.requireUpdateContact === 'true' ? true : false;
            });

            if(!isAnyNoUpdated && this.state.isLastSaved){
                window.location = endpoints.autofactProApi.base;
            }
        }

        this.setState({params, invoiceCenterList});
        return invoiceCenterList;
    }

    async getUpdateModalData(idInvoiceCenter){
        
        const contactData = await BackendApiService.getInvoiceCenterContacts(idInvoiceCenter);
               
        if(contactData.status === 'OK' && contactData.data.length > 0){
            //format contact data            
            let group = groupBy(contactData.data,'nombre');            
            const contactos = [];
            for(let contacto in group){
                let phone = group[contacto].find(item => {
                    return item.tipo_contacto === 'phone';
                })
                let email = group[contacto].find(item => {
                    return item.tipo_contacto === 'email';
                })

                const ids_centro_facturacion_contactos =  group[contacto].map(item => {return item.idcentro_facturacion_contacto})
                contactos.push({
                    id_centro_facturacion: group[contacto][0].centro_facturacion_idcentro_facturacion,
                    ids_centro_facturacion_contactos: ids_centro_facturacion_contactos,
                    nombre: contacto,
                    phone: phone ? phone.contacto : '',
                    email: email ? email.contacto : '',
                    cargo: group[contacto][0].cargo,
                    ultima_actualizacion: group[contacto][0].ultima_actualizacion,
                });
            }

            this.setState({
                contactData: contactos
            });
        } else {
            const invoiceCenter = this.state.invoiceCenterList.data.find(e => e.id === idInvoiceCenter);

            const contactos = [];

            contactos.push({
                id_centro_facturacion: idInvoiceCenter,
                ids_centro_facturacion_contactos: [],
                nombre: '',
                phone: '',
                email: invoiceCenter.collectionEmails ? invoiceCenter.collectionEmails.split(',').join(', ') : '',
                cargo: '',
                ultima_actualizacion: null,
            });

            this.setState({
                contactData: contactos
            });
        }
    }

    async showUpdateModal(idInvoiceCenter){
        await this.getUpdateModalData(idInvoiceCenter);
        this.setState({isModalOpen: true, idInvoiceCenter: idInvoiceCenter, emailValid : true, telefonoValid : true});
    }

    hideUpdateModal(){
        this.setState({isModalUpdateContactOpen: false});
    }

    showModalUpdateContact = (id_centro_facturacion, ids_centro_facturacion_contactos, nombre, telefono, email, cargo) => {
        const formData = {};
            
        formData['telefono'] = telefono;
        formData['email'] = email;
        formData['nombre'] = nombre;
        formData['cargo'] = cargo;
        formData['id_centro_facturacion'] = id_centro_facturacion;
        formData['ids_centro_facturacion_contactos'] = ids_centro_facturacion_contactos.length === 0 ? [-1] : ids_centro_facturacion_contactos;

        const newData = Object.assign(this.state.formData, formData);

        this.setState({
            isModalUpdateContactOpen: true,
            formData: newData
        });

        this.setFormStatus();

    }

    clearForm(){        
        this.setState({formData:{}});
        this.setFormStatus();
    }

    idValidNombre(nombre){
        const isValid = nombre ? nombre.length > 0 : false;
        this.setState({nombreValid: isValid});
        return isValid;         
    }

    idValidCargo(cargo){
        const isValid = cargo ? cargo.length > 0 : false;
        this.setState({cargoValid: isValid});
        return isValid;
    }

    isValidTelefono(telefono){
        const telefonoClean = replace(telefono,/ /g,'');        
        const ereg = /^[0-9 ()+-]+$/;

        const isValid = ereg.test(telefonoClean);
        this.setState({telefonoValid : isValid});
        return isValid;
    }

    isValidEmail(email){
        const listEmails = split(replace(email,/ /g,''),',');        
        const ereg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const invalidEmail = (el) => ereg.test(el) === false; 

        const isValid = !listEmails.some(invalidEmail);
        this.setState({emailValid: isValid});
        return isValid;
    }

    setFormStatus(){
        //set state of validations
        this.isValidEmail(this.state.formData.email);
        this.isValidTelefono(this.state.formData.telefono);
        this.idValidNombre(this.state.formData.nombre);
        this.idValidCargo(this.state.formData.cargo);

        //verify all validations
        const isValid = this.isValidEmail(this.state.formData.email) && 
                        this.isValidTelefono(this.state.formData.telefono) &&
                        this.idValidNombre(this.state.formData.nombre);
                        
        this.setState({formValid : isValid});
    }

    handleChangeContactData(event){
        let newData = {};
        newData[event.target.id] = event.target.value;
        newData = Object.assign(this.state.formData, newData);
        this.setState({formData: newData});
        this.setFormStatus();
    }

    async updateInvoiceCenter(){
        this.setState({formValid:false, updatingData:true});
        this.hideUpdateModal();
        const invoiceCenter = this.state.invoiceCenterList.data.find(e => e.id === this.state.idInvoiceCenter);
        const anyCenterPending = this.state.invoiceCenterList.data.some(row => {
            return row.requireUpdateContact === 'true' ? true : false;
        });        
        //update data
        const telefono = this.state.formData.telefono;
        const email = this.state.formData.email;
        const nombre = this.state.formData.nombre;
        const cargo = this.state.formData.cargo;
        const id_centro_facturacion = this.state.formData.id_centro_facturacion;
        const ids_centro_facturacion_contactos = this.state.formData.ids_centro_facturacion_contactos;

        //get all emails
        let allEmails = [];
        allEmails = this.state.contactData.map(item => {
            let invalidEmail = ids_centro_facturacion_contactos.some(id => {
                return item.ids_centro_facturacion_contactos.includes(id) || item.ids_centro_facturacion_contactos.length === 0;
            });
            if (!invalidEmail){
                return item.email;
            } else {
                return false;
            }
        }).filter(email => email);

        allEmails.push(email);

        const message = `${invoiceCenter.secondName} - Actualizando contacto: ${nombre}, cambiando email a: ${this.state.formData.email}, teléfono: ${this.state.formData.telefono}, cargo: ${cargo}`;
        
        const updateInvoiceCenter = await BackendApiService.updateInvoiceCenterContact(this.state.userData.afpr_us_user_id,id_centro_facturacion,telefono,allEmails.join(','));
        const updateTelefono = await BackendApiService.updateInvoiceCenterContactScope(id_centro_facturacion, ids_centro_facturacion_contactos,'phone',telefono, nombre, cargo);
        const updateEmail = await BackendApiService.updateInvoiceCenterContactScope(id_centro_facturacion, ids_centro_facturacion_contactos,'email',email, nombre, cargo, true, message);
        
        if ((updateInvoiceCenter.status === updateTelefono.status) === (updateEmail.status === 'OK')){
            this.InvoiceCenterContactListContainerRef.current.getWrappedInstance().reloadData();            
            if(anyCenterPending){
                this.setState({isLastSaved : true});
            }
            await this.getUpdateModalData(id_centro_facturacion);
            console.log('datos de contacto actualizados')           
        } else {
            alert('Ocurrió un error al actualizar los datos de contacto');            
            console.log('error al actualizar los datos de contacto')
        }
        this.setState({updatingData:false});
    }
    
    renderInfoModal(){
        return <Modal isOpen={this.state.isModalInfoOpen} toggle={this.toggleInfo} className={this.props.className} style={{fontSize:'1.2em'}}>
            <ModalHeader toggle={this.toggleInfo} ><span style={{fontSize:'1.2em'}}>Estimado cliente</span></ModalHeader>
            <ModalBody>
            Necesitamos de su ayuda para actualizar su información de contacto, con esto podemos asegurarnos que en nuestras bases de datos tenemos la información correcta para comunicarnos con usted en lo referente a facturación, órdenes de compra, notificaciones de cobranzas y otros.
            <br /><br />
            <i>Le pedimos <strong>encarecidamente</strong> hacer click en el siguiente botón y completar la información requerida.</i>
            <br /><br />
            ¡Muchas gracias!
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => {this.setState({isModalInfoOpen:false})}}>Aceptar</Button>
            </ModalFooter>
        </Modal>
    }

    renderUpdateModal(){
        return  <Modal isOpen={this.state.isModalOpen} toggle={this.toggle} className="modal-lg" >
            <ModalHeader toggle={this.toggle}>Actualizar datos de contacto</ModalHeader>
            <ModalBody>
                <div className="row"> 
                    <div className="col-md-12">
                        <Button className="btn-sm btn-success" onClick={() => {
                                                        this.showModalUpdateContact(
                                                            this.state.idInvoiceCenter,
                                                            [],
                                                            '',
                                                            '',
                                                            '',
                                                            '')}}>
                                                            <i className="fa fa-plus"></i>
                        </Button>
                        <br /><br />
                        <Alert isOpen={this.state.updatingData} color="info">Actualizando datos...</Alert>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Nombre y apellido</th>
                                    <th>Cargo</th>
                                    <th>Correo</th>
                                    <th>Teléfono</th>
                                    <th>Ult. actualización</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.contactData.map((value, index) => {
                                    return <tr key={`key-contact-${value.id_centro_facturacion}-${value.nombre}`}>
                                                <td>{value.nombre}</td>
                                                <td>{value.cargo}</td>
                                                <td>{value.email}</td>
                                                <td>{value.phone}</td>
                                                <td>{value.ultima_actualizacion ? moment(value.ultima_actualizacion).format('DD/MM/YYYY') : ''}</td>
                                                <td>
                                                    <Button size="sm" onClick={() => {
                                                        this.showModalUpdateContact(
                                                            value.id_centro_facturacion,
                                                            value.ids_centro_facturacion_contactos,
                                                            value.nombre,
                                                            value.phone,
                                                            value.email,
                                                            value.cargo)}} >
                                                            <i className="fa fa-edit"></i> Editar
                                                    </Button>
                                                </td>
                                            </tr>;
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="btn btn-sm btn-primary btn" onClick={() => {this.setState({isModalOpen:false})}} >Cerrar</Button>
            </ModalFooter>
        </Modal>
    }

    renderUpdateContact = () => {
        return  <Modal isOpen={this.state.isModalUpdateContactOpen} toggle={this.toggleModalUpdateContactOpen} className="modal-sm" >
        <ModalHeader toggle={this.toggleModalUpdateContactOpen}>Actualizar contacto</ModalHeader>
        <ModalBody>
            <div className="row">
                <div className="col-sm-12">
                    <Form>
                        <FormGroup row>
                            <Label for="nombre" sm={12}>Nombre</Label>
                            <Col sm={12}>
                                <Input type="text" name="nombre" id="nombre" value={ this.state.formData.nombre || "" } onChange={this.handleChangeContactData} placeholder="Nombre" />
                                <Label style={{color:'red'}} hidden={this.state.nombreValid}>Debe ingresar un nombre</Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="cargo" sm={12}>Cargo</Label>
                            <Col sm={12}>
                                <Input type="text" name="cargo" id="cargo" value={ this.state.formData.cargo || "" } onChange={this.handleChangeContactData} placeholder="Cargo" />
                                <Label style={{color:'red'}} hidden={this.state.cargoValid}>Debe ingresar un cargo</Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="ammount" sm={12}>Teléfono</Label>
                            <Col sm={12}>
                                <Input type="text" name="telefono" id="telefono" value={ this.state.formData.telefono || "" } onChange={this.handleChangeContactData} placeholder="ejemplo: 965798378" />
                                <Label style={{color:'red'}} hidden={this.state.telefonoValid}>Sólo se permiten números</Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="paydate" sm={12}>Email</Label>
                            <Col sm={12}>
                                <Input type="text" name="Email" id="email" value={ this.state.formData.email || "" } onChange={this.handleChangeContactData} placeholder="ejemplo: juan@gmail.com"/>
                                <Label style={{color:'red'}} hidden={this.state.emailValid}>Ingrese un email válido (o un listado separado por comas)</Label>
                            </Col>
                        </FormGroup>
                    </Form>
                </div>
            </div>
            
        </ModalBody>
        <ModalFooter>
            <Button className="btn btn-sm btn-primary-darker btn btn-secondary mr-2" disabled={!this.state.formValid} onClick={this.updateInvoiceCenter}>Guardar</Button>
            <Button className="btn btn-sm btn-primary btn" onClick={() => {this.setState({isModalUpdateContactOpen:false})}} >Cerrar</Button>
        </ModalFooter>
        </Modal>;
    }

    render(){
        return(
            <div>
                <InvoiceCenterContactList 
                    ref={this.InvoiceCenterContactListContainerRef}
                    id={this.props.id}
                    fetchDataCallback={this.fetchData}
                    showUpdateModal={this.showUpdateModal}
                />
                {this.renderInfoModal()}
                {this.renderUpdateModal()}
                {this.renderUpdateContact()}
            </div>
        );
    }
}

export default InvoiceCenterContactListContainer;
