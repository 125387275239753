import 'bootstrap/dist/css/bootstrap.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
    faUser, 
    faBuilding, 
    faSignOutAlt, 
    faSignInAlt, 
    faSpinner, 
    faFileInvoice,
    faFileInvoiceDollar,
    faUpload,
    faCalendarAlt,
    faPaperclip,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';

import './styles/main.css';
import MainLayout from './layouts/MainLayout';

library.add(
    faUser, 
    faBuilding, 
    faSignOutAlt, 
    faSignInAlt, 
    faSpinner,
    faFileInvoice,
    faFileInvoiceDollar,
    faUpload,
    faCalendarAlt,
    faPaperclip,
    faTrash
);

export {
    MainLayout
};
