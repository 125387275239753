import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PaymentSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: props.userData,
    };
  }

  render() {
    return (
      <div>
        {this.state.userData.afpr_us_data ? (
          <div className="payment-success">
            <div className="page-title">
              <FontAwesomeIcon
                icon="file-invoice"
                className="icon-title"
                alt="Icono de Facturas"
              />
              <h2 className="text-title">EL PAGO DE LOS DOCUMENTOS HA SIDO REALIZADO EXITOSAMENTE</h2>
            </div>
            <div className="main-container">
              <h2 className="text-title">¡Felicitaciones! El pago se ha realizado con éxito</h2>
              <p className="text">Se ha efectuado correctamente el pago de las facturas pendientes seleccionadas. Puedes ver el estado en la plataforma de cobranza</p>
              <p className="text-center">
                <a className="btn btn-bg-orange-white back" href='/facturas'>Volver al listado de facturas</a>
              </p>
            </div>
          </div>
        ) : (
            <Redirect to="/login" />
          )}
      </div>
    );
  }
}

export default connect(store => ({
  userData: store.app.userData
}))(PaymentSuccess);
