import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import AuthService from '../../services/AuthService';

const PrivateRoute = ({component: Component, path, isLoggedIn, permission = false}) => (
    <Route
        exact
        path={path}
        render={() =>
            isLoggedIn ? (
                 permission === false || AuthService.canDo(permission) ?
                    <Component/>
                    :
                    <Redirect
                        to={'403'}
                    />
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: {from: path}
                    }}
                />
            )
        }
    />
);

export default PrivateRoute;
