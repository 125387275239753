import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ShowDocument extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
        this.dataTableRef = React.createRef();
    }
    componentDidMount() {
        this.setState({loading: true});
        this.pdfIframe.onload = () => {
            this.setState({loading: false});
        }
    }

    closeDialog = () =>{
        if(typeof this.props.closeDialog === 'function')
        {
            this.props.closeDialog();
        }
    }

    render() {
        return (
            <Modal isOpen={true} className={this.props.className}>
                <ModalHeader>Ver documento</ModalHeader>
                <ModalBody>
                    <div>
                        {this.state.loading ? 'Cargando' :''}
                        <div id="document-browser" className={`embed-responsive embed-responsive-1by1`}>
                            <iframe
                                ref={ref => this.pdfIframe = ref}
                                src={this.props.src}
                                title="ver documento"
                                className={`embed-responsive-item`}
                            />
                        </div>
                </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-sm badge-primary-darker" onClick={this.closeDialog}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ShowDocument;
