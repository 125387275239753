import React, {Component} from 'react';
import BackendApiService from "../../services/BackendApiService";
import {Button} from "reactstrap";
import excelIcon from '../../theme/assets/ico-excel.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TransferenceList from "../transference-list/TransferenceList";

class TransferenceListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            transferenceList:[],
            transferencePriorityValues: {},
            params: this.props.filters || {},
            transferenceSelected: {},
            fetchParams: ['where', 'per_page', 'page'],
            userData: props.userData,
            loaded: false,
            loadedError:null,
            exportingSelectedExcel: false,
            exportingExcel: false,
            exportResult:'',
            exportSelectedResult:''
        };
        this.fetchData                        = this.fetchData.bind(this);
        this.selectedRow                        = this.selectedRow.bind(this);
        this.download = this.download.bind(this);
        this.transferenceListComponentRef = React.createRef();
    }
    componentDidMount(){}
    filterParams(params) {
        const filtered = Object.keys(params)
            .filter(key => this.state.fetchParams.includes(key))
            .reduce((obj, key) => {
                obj[key] = params[key];
                return obj;
            }, {});
        return filtered;
    }


    async fetchData(params) {
        params = Object.assign(this.state.params, params);
        params = this.filterParams(params);
        let transferenceList = await BackendApiService.getTransferences(params);
        if(transferenceList){
            this.props.updateCount(transferenceList.extra.recordsTotal);
        }else{
            this.props.updateCount(0);
            transferenceList = []
        }

        this.setState({params, transferenceList});
        return transferenceList;

    }
    selectedRow(e, item){
        const selecteds = Object.assign({}, this.state.transferenceSelected);
        if(e.target.checked){
            selecteds[item.id] = true;
        }else{
            delete selecteds[item.id];
        }
        this.setState({transferenceSelected: selecteds});
    }
    
    updateTransferencePriority = async (item, priority)=>{
        try{
            await BackendApiService.updateTransferencePriority(item.code,priority)
            const transferencePriorityValues = Object.assign({}, this.state.transferencePriorityValues);
            transferencePriorityValues[item.code] = priority;
            this.setState({transferencePriorityValues:transferencePriorityValues})
        }catch (e){
            console.log(e);
        }

    }
    async exportData(format, from) {
        const params = this.state.params;
        params.format = format;
        params.from = from;
        params.tab = this.props.tab
        if(params.from === 'selected'){
            params.IDS = Object.keys(this.state.transferenceSelected);
        }
        const exportResult = await BackendApiService.getTransferences(params);
        return exportResult.data;
    }
    download(format, from) {
        const exportingContext = from === 'all'? 'exportingExcel':'exportingSelectedExcel';
        const exportingResultContext = from === 'all'? 'exportResult':'exportSelectedResult';
        this.setState({[exportingContext]: true})
        let link = document.createElement('a');
        link.target = '_blank';
        this.exportData(format, from)
            .then((response) => {
                this.setState({[exportingContext]: false, [exportingResultContext]: response.url});
            })
            .catch(() => {
                alert('Error exportando datos');
                this.setState({[exportingContext]: false});
            });
    }
    refreshFilters(filters) {
        this.setState({params: filters});
        setTimeout(() => {
            if(this.transferenceListComponentRef.current)
                this.transferenceListComponentRef.current.reloadData();
        }, 100);
    }
    renderExportSelectedButton() {
        const style = {
            borderRadius: '5px',
            fontFamily: 'Roboto',
            fontSize: '14px'
        }
        return this.state.exportSelectedResult ? <a
            key="export-xls-download"
            href={this.state.exportSelectedResult}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => this.setState({exportSelectedResult: ''})}
            className={'btn donwload-button btn-sm actions'}
            disabled={this.state.exportingSelectedExcel}>
            <FontAwesomeIcon icon="file-download"/>
            Descargar Excel
        </a> : <Button
            className="btn btn-sm btn-outline-primary-darker"
            style={style}
            onClick={(e)=>this.download('xlsx', 'selected')}
            disabled={Object.keys(this.state.transferenceSelected).length === 0}
        >
            <img className="excelIcon" src={excelIcon} alt="Excel icon"/>&nbsp;&nbsp;
            {this.state.exportingSelectedExcel ? "Exportando..." : "Exportar seleccionados a Excel"}
        </Button>

    }
    renderExportFullButton() {
        const style = {
            borderRadius: '5px',
            fontFamily: 'Roboto',
            fontSize: '14px'
        }
        return this.state.exportResult ? <a
            key="export-xls-download"
            href={this.state.exportResult}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => this.setState({exportResult: ''})}
            className={'btn donwload-button btn-sm actions'}
            disabled={this.state.exportingExcel}>
            <FontAwesomeIcon icon="file-download"/>
            Descargar Excel
        </a> : <Button
            className="btn btn-sm btn-outline-primary-darker"
            style={style}
            onClick={(e)=>this.download('xlsx', 'all')}
        >
            <img className="excelIcon" src={excelIcon} alt="Excel icon"/>&nbsp;&nbsp;
            {this.state.exportingExcel ? "Exportando..." : "Exportar todos a Excel"}
        </Button>

    }

    render() {
        const includeExportComponent = true;
        return (
            <div>
                <div>
                    <TransferenceList
                        ref={this.transferenceListComponentRef}
                        id={this.props.id}
                        fetchDataCallback={this.fetchData}
                        selectedRow={this.selectedRow}
                        selecteds={this.state.transferenceSelected}
                        updateTransferencePriority={this.updateTransferencePriority}
                        transferencePriorityValues={this.state.transferencePriorityValues}
                    />
                </div>
                {
                    includeExportComponent &&
                        <div className="col d-flex justify-content-between">
                            {this.renderExportSelectedButton()}
                            {this.renderExportFullButton()}
                        </div>
                }
            </div>
        );
    }
}

export default TransferenceListContainer;
