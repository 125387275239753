import React, { Component } from "react";
import { Collapse, Nav, NavItem, NavLink, Button } from "reactstrap";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InvoiceCenterClosureListContainer from "../../components/invoice-center-closure-list-container/InvoiceCenterClosureListContainer";
import InvoiceCenterClosureListFilter from "../../components/invoice-center-closure-list-filter/InvoiceCenterClosureListFilter";
import InvoiceCenterClosureSummaryContainer from "../../components/invoice-center-closure-summary-container/InvoiceCenterClosureSummaryContainer";

import FilterIcon from "../../theme/assets/filter_conces.svg";

import { buildClosureFilters } from '../../helpers/filterBuilder';

class InvoiceCenterClosureList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtersCollapsed: false,
      filters: {},
      userData: props.userData
    };
    this.toggleFilters = this.toggleFilters.bind(this);
    this.setFiltersCallback = this.setFiltersCallback.bind(this);
    this.currentTableRef = React.createRef();
    this.filterComponentRef = React.createRef();
    this.summaryComponentRef = React.createRef();
  }

  toggleFilters() {
    this.setState({ filtersCollapsed: !this.state.filtersCollapsed });
  }

  buildFilters(filters = null) {
    const where = filters || this.state.filters;
    return buildClosureFilters(this.props.userData, where);
  }

  setFiltersCallback(filters) {
    this.setState({ filters });
    const buildedFilters = this.buildFilters(filters);
    this.currentTableRef.current.refreshFilters(buildedFilters);
    this.summaryComponentRef.current.fetchData(buildedFilters);
  }
  componentDidMount() {
    const buildedFilters = this.buildFilters();
    this.summaryComponentRef.current.fetchData(buildedFilters);
  }

  render() {
    return (
      <div>
        {this.state.userData.afpr_us_data ? (
          <div>
            <div className="page-title">
              <FontAwesomeIcon
                icon="file-invoice-dollar"
                className="icon-title"
                alt="Icono de Cierres"
              />
              <h2 className="text-title">Cierres Antiguos</h2>
            </div>
            <div className={"pb-4"}>
              <InvoiceCenterClosureSummaryContainer
                ref={this.summaryComponentRef}
              />
            </div>
            <Nav tabs className="tabs-dark">
              <NavItem key={`tab-nav-item-${1}`}>
                <NavLink key={`tab-nav-${1}`} className={""}>
                  {"Cierres Antiguos"}
                </NavLink>
              </NavItem>
              <NavItem className="nav-button">
                <Button
                  className="btn btn-sm btn-primary-dark"
                  onClick={this.toggleFilters}
                >
                  <img
                    className="filter-icon"
                    src={FilterIcon}
                    alt="Filter Icon"
                  />{" "}
                  Filtros
                </Button>
              </NavItem>
            </Nav>
            <div>
              <Collapse isOpen={this.state.filtersCollapsed}>
                <div className="filter-form-wrapper">
                  <InvoiceCenterClosureListFilter
                    ref={this.filterComponentRef}
                    setFiltersCallback={this.setFiltersCallback}
                  />
                </div>
              </Collapse>
            </div>
            <InvoiceCenterClosureListContainer
              id={"invoice-center-closure-list"}
              ref={this.currentTableRef}
              filters={this.buildFilters()}
            />
          </div>
        ) : (
            <Redirect to="/login" />
          )}
      </div>
    );
  }
}

export default connect(store => ({
  userData: store.app.userData
}))(InvoiceCenterClosureList);
