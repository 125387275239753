import store from '../store';
import constants from '../constants';

const dispatch = store.dispatch;

export function changeBillingCount(value) {
    dispatch({type: constants.general.changeBillingCount,billingCount: value});
}

export function changeSaleNotesCount(value) {
  dispatch({type: constants.general.changeSaleNotesCount,saleNotesCount: value});
}

export function changeClosureCount(value) {
    dispatch({type: constants.general.changeClosureCount,closureCount: value});
}

export function changeUserData(value) {
    dispatch({type: constants.general.changeUserData,userData: value});
}


