import React, {Component} from 'react';
import BackendApiService from "../../services/BackendApiService";
import { changeSaleNotesCount } from '../../store/dispatchers/app'

import SaleNotesList from "./SaleNotesList";

class SaleNotesListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saleNotesList:[],
            params: this.props.filters || {},
        };
        this.fetchData                        = this.fetchData.bind(this);
        this.saleNotesComponentRef = React.createRef();
    }

    async fetchData(params) {
        params = Object.assign(this.state.params, params)
        const saleNotesList = await BackendApiService.getPaymentDocuments(params);
        changeSaleNotesCount(saleNotesList.extra.recordsTotal);
        this.setState({params, saleNotesList});
        return saleNotesList;
    }

    refreshFilters(filters) {
        this.setState({params: filters});
        setTimeout(() => {
            if (this.saleNotesComponentRef.current){
                this.saleNotesComponentRef.current.getWrappedInstance().reloadData();
            }
        }, 100);
    }

    render() {
        return (
            <div>
                <SaleNotesList
                    ref={this.saleNotesComponentRef}
                    id={this.props.id}
                    fetchDataCallback={this.fetchData}
                />
            </div>
        );
    }
}

export default SaleNotesListContainer;
