import React, {Component} from 'react';
import BackendApiService from "../../services/BackendApiService";
import {changeBillingCount} from '../../store/dispatchers/app'

import InvoiceCenterBillingList from "../invoice-center-billing-list/InvoiceCenterBillingList";

class InvoiceCenterBillingListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoiceCenterBillingList:[],
            params: this.props.filters || {},
        };
        this.fetchData                        = this.fetchData.bind(this);
        this.invoiceCenterBillingComponentRef = React.createRef();
    }

    async fetchData(params) {
        params = Object.assign(this.state.params, params)
        const invoiceCenterBillingList = await BackendApiService.getPaymentDocuments(params);
        changeBillingCount(invoiceCenterBillingList.extra.recordsTotal);
        this.setState({params, invoiceCenterBillingList});
        return invoiceCenterBillingList;
    }

    refreshFilters(filters) {
        this.setState({params: filters});
        setTimeout(() => {
            if (this.invoiceCenterBillingComponentRef.current){
                this.invoiceCenterBillingComponentRef.current.getWrappedInstance().reloadData();
            }
        }, 100);
    }
    render() {
        return (
            <div>
                <InvoiceCenterBillingList
                    ref={this.invoiceCenterBillingComponentRef}
                    id={this.props.id}
                    fetchDataCallback={this.fetchData}
                />
            </div>
        );
    }
}

export default InvoiceCenterBillingListContainer;
