const TICKET = 'BOLETA';
const INVOICE    = 'FACTURA';
const CREDIT_NOTE    = 'NOTA CREDITO';
const DEBT_NOTE    = 'NOTA DEBITO';
const CHARGE_NOTE    = 'NOTA COBRO';
const SALE_NOTE = 'NOTA VENTA';

export default {
    TICKET,
    INVOICE,
    CREDIT_NOTE,
    DEBT_NOTE,
    CHARGE_NOTE,
    SALE_NOTE,
    billingValidTypes(){
        return [
            INVOICE, CREDIT_NOTE, DEBT_NOTE
        ]
    }
};
