import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button } from 'reactstrap';
import moment from 'moment';

import DataTable from '../data-table/DataTable';
import BillingStatus from '../../services/BillingStatus';
import BackendApiService from '../../services/BackendApiService';
import moneyFormat from '../../helpers/MoneyFormat';
import NotifiedPayment from '../../components/notified-payment/NotifiedPayment';
import ShowDetails from '../../components/details/ShowDetails';
import CustomTooltip from '../../helpers/Tooltip';
class SaleNotesList extends Component {
    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();

        this.state = {
            userData: props.userData,
            modalNotifiedPaymentIsOpen: false,
            paymentDocId: '',
            paymentDocsSelected: {},
            sendingNotification: false,
            showDetails: false,
            currentItem: {},
        }
    }

    reloadData = () => {
        this.dataTableRef.current.reloadData();
    }

    renderTaxIdentifier(item) {
        if (item.identificador_tributario) {
            return item.identificador_tributario.replace(/.*?-/, '')
        } else
            return '-';
    }

    renderBillingStatus(item) {
        let classes = '';
        if (item.estado === BillingStatus.PENDING && !item.isLate) {
            classes = 'bg-pending';
        } else if ((item.estado === BillingStatus.PENDING || item.estado === BillingStatus.LATE) && item.isLate) {
            classes = 'bg-red text-white';
            item.estado = BillingStatus.LATE;
        } else if (item.estado === BillingStatus.PAID) {
            classes = 'bg-paid';
        } else if (item.estado === BillingStatus.TO_CONFIRM) {
            classes = 'bg-to-confirm'
        }
        return <span className={`${classes} closure-status`}>{item.estado === 'LATE' ? 'VENCIDO' : BillingStatus.getName(item.estado)}</span>;
    }

    renderEndDate(item) {
        if (!item.timestamp_emision)
            return '';
        const _date = moment(item.timestamp_emision).add(7, 'days');
        if (_date.isValid()) {
            return <span className="table-text">{_date.format('DD-MM-YYYY')}</span>
        }
    }

    renderStartDate(item) {
        const _date = moment(item.timestamp_emision);
        if (_date.isValid()) {
            return <span className="table-text">{_date.format('DD-MM-YYYY')}</span>
        }
    }

    renderValue(item) {
        return <span className={`table-text text-center`}>${moneyFormat(item.total)}</span>
    }

    renderAmmount(item) {
        if (item.estado === 'PAID') {
            return <span className={`table-text text-center`}>${moneyFormat(0)}</span>
        }
        return <span className={`table-text text-center`}>${moneyFormat(item.total - item.total_pagado || 0)}</span>
    }

    renderInvoiceCenter(item) {
        return <span className={`table-text text-center`}>{item.nombre}</span>
    }

    renderInvoiceCenterShortName(item) {
        return <span className={`table-text text-center`}>{item.nombre_corto}</span>
    }

    renderCheckBox(item) {
        return <input type="checkbox" className="form-control-sm" style={{ width: 17 }}
            onChange={(e) => this.onChangeChecked(e, item)} value={item.iddocumento_pago}
            checked={this.state.paymentDocsSelected.hasOwnProperty(item.iddocumento_pago)}>
        </input>
    }

    onChangeChecked = (e, item) => {
        const docsSelected = Object.assign({}, this.state.paymentDocsSelected);
        if (e.target.checked) {
            if ([BillingStatus.PENDING, BillingStatus.LATE].includes(item.estado)) {
                docsSelected[item.iddocumento_pago] = item;
            }
        } else {
            delete docsSelected[item.iddocumento_pago];
        }
        this.setState({ paymentDocsSelected: docsSelected });
    }

    downLoadDetails(e, item) {
        e.preventDefault();
        this.props.downLoadDetails(item);
    }

    // notified payment
    openNotifiedPaymentModal = (e) => {
        e.preventDefault();
        this.setState({ modalNotifiedPaymentIsOpen: true });
    }
    closeModalNotifiedPayment = () => {
        this.setState({ modalNotifiedPaymentIsOpen: false });
    }

    closeModalDetails = () => {
        this.setState({ showDetails: false });
    }

    async showModalDetails(e, item) {
        e.preventDefault();
        console.log('item', item);
        this.setState({ showDetails: true, currentItem: item });
    }

    notifiedPayment = async (formData) => {
        try {
            this.setState({ sendingNotification: true });
            const docsIds = Object.keys(this.state.paymentDocsSelected).map(id => parseInt(id));
            const changeStatusParams = { paymentDocsIds: docsIds, status: BillingStatus.TO_CONFIRM };
            // change selected invoices status
            const changeStatusResponse = await BackendApiService.changePaymentDocStatus(changeStatusParams);
            if (changeStatusResponse.status === 'OK') {
                const sendNotificationResponse = await this._sendEmailPaymentNotification(formData);
                if (sendNotificationResponse) {
                    this.setState({ modalNotifiedPaymentIsOpen: false, sendingNotification: false, paymentDocsSelected: {} })
                    alert('Fue enviada la notificación de pago satisfactoriamente');
                    this.reloadData();
                    return true;
                }
            }

            alert('Ocurrió un error enviando la notificación de pago');
            this.setState({ sendingNotification: false })
        } catch (error) {
            console.error(error);
            alert('Ocurrió un error enviando la notificación de pago');
        }
    }
    /**
     * Send email about payment notification of invoices
     * @param formData payment form data
     */
    _sendEmailPaymentNotification = async (formData) => {
        const { socialReason } = this._getUserData(this.props.userData);
        const { amount } = formData;
        const docsSelected = Object.values(this.state.paymentDocsSelected);
        let sumTotals = 0;
        const invoicesData = docsSelected.map(doc => {
            sumTotals += doc.total;
            const mapped = {};
            mapped.docLegalIdentifier = doc.identificador_tributario;
            mapped.status = BillingStatus.getName(BillingStatus.TO_CONFIRM);
            mapped.emissionDate = doc.timestamp_emision;
            mapped.totalDoc = `$${moneyFormat(doc.total)}`;
            return mapped;
        })
        const beautifyAmount = `$${moneyFormat(amount.replace(/\./g, ''))}`;
        const emailNotificationParams = formData;
        const extraParams = {
            amount: beautifyAmount, invoicesData, socialReason, sumTotals: `$${moneyFormat(sumTotals)}`
        }
        return await BackendApiService.sendEmailPaymentNotification(Object.assign(emailNotificationParams, extraParams));
    }
    _getUserData = (userData) => {
        try {
            const email = userData.usuario.mail;
            const socialReason = userData.usuario.centro_facturacion.nombre;
            return { email, socialReason };
        } catch (e) {
            console.log(e);
            return {};
        }
    }

    render() {
        const columns = [
            {
                Header: '',
                width: 30,
                Cell: row => {
                    return this.renderCheckBox(row.original)
                }
            },
            {
                Header: 'Correlativo',
                accessor: 'identificador_tributario',
                className: `table-text text-center`,
                headerClassName: 'wordwrap',
                width: 90,
                Cell: row => {
                    return this.renderTaxIdentifier(row.original)
                }
            }, {
                Header: 'Centro de facturación',
                accessor: 'type',
                headerClassName: 'wordwrap',
                Cell: row => {
                    return this.renderInvoiceCenter(row.original)
                }
            }, {
                Header: 'Sucursal',
                accessor: 'type',
                headerClassName: 'wordwrap',
                Cell: row => {
                    return this.renderInvoiceCenterShortName(row.original)
                }
            },
            {
                Header: 'Fecha de emisión',
                accessor: 'timestamp_emision',
                headerClassName: 'wordwrap',
                width: 95,
                Cell: row => {
                    return this.renderStartDate(row.original)
                }
            },
            {
                Header: 'Fecha de vencimiento',
                accessor: 'timestamp_emision',
                headerClassName: 'wordwrap',
                width: 95,
                Cell: row => {
                    return this.renderEndDate(row.original)
                }
            },
            {
                Header: 'Valor',
                accessor: 'total_sin_impuesto',
                headerClassName: 'wordwrap',
                width: 80,
                Cell: row => {
                    return this.renderValue(row.original)
                }
            },
            {
                Header: 'Saldo',
                accessor: 'saldo',
                headerClassName: 'wordwrap',
                width: 80,
                Cell: row => {
                    return this.renderAmmount(row.original)
                }
            },
            {
                Header: 'Estado',
                accessor: 'estado',
                headerClassName: 'wordwrap',
                width: 120,
                Cell: row => {
                    return this.renderBillingStatus(row.original)
                }
            },
            {
                Header: 'Ver',
                accessor: 'total',
                width: 180,
                Cell: row => {
                    const buttons = [];
                    if (row.original.url_pdf_documento) {
                        buttons.push(<a key="billing"
                            className={`btn btn-sm btn-fill-detail mr-2`}
                            href={row.original.url_pdf_documento} target="blank">
                            DOCUMENTO
                        </a>)
                    }
                    if (row.original.consolidado_detalle) {
                        buttons.push(<button key="detail" className={`btn btn-sm btn-outline-detail`}
                            onClick={(e) => { this.showModalDetails(e, row.original) }}>
                            DETALLE
                        </button>)
                    }
                    return buttons;
                }
            },
        ];

        return (
            <div className={`invoice-center-closure-list-wrapper tab-pane`}>
                {this.state.showDetails && <ShowDetails
                    item={this.state.currentItem}
                    className='cobranza-modal draft'
                    closeDialog={this.closeModalDetails}
                />}
                {
                    this.state.modalNotifiedPaymentIsOpen &&
                    <NotifiedPayment
                        closeModalHandler={this.closeModalNotifiedPayment}
                        notifiedPaymentHandler={this.notifiedPayment}
                        loading={this.state.sendingNotification}
                    />
                }
                <div className="invoice-center-billing content-buttons">
                    <CustomTooltip content="Selecciona los cierres que deseas notificar" placement="bottom">
                        <span className="notifyButton">
                            <Button disabled={Object.keys(this.state.paymentDocsSelected).length === 0}
                                style={Object.keys(this.state.paymentDocsSelected).length === 0 ? { pointerEvents: "none" } : {}}
                                onClick={this.openNotifiedPaymentModal}
                                className="btn btn-sm btn-primary-dark btn btn-secondary btn-notified-payment invoices">
                                Notificar pago
                            </Button>
                        </span>
                    </CustomTooltip>
                </div>
                <hr></hr>
                <DataTable
                    ref={this.dataTableRef}
                    key={`data-table-${this.props.id}`}
                    id={this.props.id}
                    columns={columns} {...this.props}
                />
            </div>
        );
    }
}

export default connect(store => ({
    userData: store.app.userData
}), null, null, { withRef: true })(SaleNotesList);