import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import AuthService from "../../services/AuthService";

class Logout extends Component {

    state = {
        isLogout: false
    }
    async componentWillMount() {
        await AuthService.logout();
        this.setState({isLogout:true})
        this.props.setIsAuthenticated(false);
    }

    render() {
        return (
            <div>
                Login out...
                {this.state.isLogout && <Redirect to={'/login'}/>}
            </div>
        );
    }
}

export default Logout