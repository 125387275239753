import React, {Component} from 'react';
import DataTable from '../data-table/DataTable';
import moment from 'moment';
import moneyFormat from '../../helpers/MoneyFormat'
import config from '../../config'
import { Input} from "reactstrap";


import { getTransferenceTypeFromId } from '@autofact/operation-lib-model-notary-platform';

class TransferenceList extends Component {
    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();
    }

    reloadData() {
        this.dataTableRef.current.reloadData();
    }

    renderPlate(item) {
        if(item.plate && item.code){
            return <a className="text-primary-dark" href={`${config.endpoints.autofactProApi.base}transferencia/ver/${item.code}`} >{item.plate}</a>
        }else{
            return '-'
        }
    }
    changePriority = (e, item)=>{
        this.props.updateTransferencePriority(item, e.target.value);
    }
    renderTransferType(item) {
        if(item.details && item.details.tipo_contrato){
            return item.details.tipo_contrato
        }else{
            return '-'
        }
    }

    renderDate(item) {
        const _date = moment.utc(item.last_transition_timestamp);
        if(_date.isValid()){
            return <span className="table-text">
                <span style={{fontWeight:'bold'}}>{_date.format('DD-MM-YYYY')}</span>&nbsp;
                <span>{_date.format('HH:mm:ss')}</span>
            </span>
        }
    }

    renderAmmount(item){
        return  <span>${moneyFormat(item.ammount)}</span>;
    }


    render() {
        let dateNameColumn = 'Fecha';
        if(this.props.id === 'transfer_tax'){
            dateNameColumn = 'Fecha llegada a impuesto'
        }else if(this.props.id === 'transfer_mangament'){
            dateNameColumn = 'Fecha llegada a gestión'
        }
        const columns = [
            {
                Header: 'Exportar a Excel',
                accessor: 'id',
                headerClassName: 'wordwrap header-opacity',
                width: 70,
                Cell: row => {
                    return <label className="custom-check">
                        <input
                            type="checkbox"
                            checked={typeof this.props.selecteds[row.original.id] !== 'undefined'}
                            onClick={(e)=>this.props.selectedRow(e, row.original)}/>
                        <span className="checkmark"></span>
                    </label>
                }
            },
            {
                Header: dateNameColumn,
                accessor: 'details',
                headerClassName:'wordwrap',
                Cell: row => {
                    return this.renderDate(row.original)
                }
            }, {
                Header: 'Patente',
                accessor: 'plate',
                headerClassName:'wordwrap',
                Cell: row => {
                    return this.renderPlate(row.original)
                }
            }, {
                Header: 'Tipo contrato',
                accessor: 'plate',
                headerClassName:'wordwrap',
                Cell: row => {
                    return getTransferenceTypeFromId(row.original.type).shortLabel
                }
            }, {
                Header: 'Monto impuesto',
                accessor: 'plate',
                headerClassName:'wordwrap',
                Cell: row => {
                    return moneyFormat(row.original.TransferTax.amount)
                }
            }, {
                Header: 'Inscripción',
                accessor: 'plate',
                headerClassName:'wordwrap',
                Cell: row => {
                    return moneyFormat(row.original.rc_inscription)
                }
            }, {
                Header: 'Prohibición',
                accessor: 'prohibitionAmount',
                headerClassName: 'wordwrap',
                Cell: row => {
                    return moneyFormat(row.original.prohibitionAmount)
                }
            }, {
                Header: 'Monto total',
                accessor: 'plate',
                headerClassName:'wordwrap',
                Cell: row => {
                    console.log(row.original.TransferTax.amount);
                    return moneyFormat(row.original.ammount_full);
                }
            }, {
                Header: 'Prioridad',
                accessor: 'plate',
                headerClassName:'wordwrap',
                Cell: row => {
                    const priority = this.props.transferencePriorityValues[row.original.code] || row.original.with_priority;
                    const className = ['form-control-sm'];

                    if(parseInt(priority) === 1){
                        className.push('text-red')
                    }else{
                        className.push('text-green-darker')
                    }
                    return <Input type="select" id="priority" className={className.join(' ')}
                                  onChange={(e)=>{this.changePriority(e, row.original)}}
                                  value={priority}>
                        <option key="yes_option" value="1">SI</option>
                        <option key="no_option" value="0">NO</option>
                    </Input>
                }
            }
        ];

        return (
            <div className={`transference-list-wrapper tab-pane`}>
                <DataTable
                    ref={this.dataTableRef}
                    key={`data-table-${this.props.id}`}
                    id={this.props.id}
                    columns={columns} {...this.props}
                />
            </div>
        );
    }
}

export default TransferenceList;
