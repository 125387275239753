/**
 * Created by Ale on 7/23/2019.
 */
import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, Col, FormGroup, Form, Input, Label, Row } from 'reactstrap';
import BackendApiService from '../../services/BackendApiService';

class ShowDraft extends Component {
  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      items: [],
      draft: {},
      draftInvoiceCenter: {}
    }
  }

  componentDidMount() {
    this.setState({loading: true});
    BackendApiService.findDraftDocumentData({paymentDocId: this.props.paymentDocId})
      .then(data => {
        const {items, draft, draftInvoiceCenter} = data;
        const itemsWithMovement = [];
        for (const item of items) {
          // show only products with price > 0
          if (item.unit_price > 0)
            itemsWithMovement.push(item);
        }
        this.setState({items: itemsWithMovement, draft, draftInvoiceCenter, loading: false});
      })
      .catch(error => {
        console.error(error);
        this.setState({loading: false});
        alert('Ocurrió un error al buscar los datos del documento');
      })
  }

  render() {
    const ocNumbers = this.state.draft.numeros_orden_compra || '';
    const ocDates = this.state.draft.fechas_orden_compra || '';
    const itemsMapped = this.state.items.map((item, index)=> {
      return <tr key={index}>
        <td>{item.description}</td>
        <td>{item.amount}</td>
        <td>{item.unit_price}</td>
        <td>{item.value_total}</td>
      </tr>
    });

    return (
      <Modal size="lg" isOpen={true} centered={true} className="cobranza-modal draft">
        <ModalHeader className="bg-155892">
          <label className="header-label font-weight-bold m-0">Borrador</label>
          <button type="button" className="close" aria-label="Close" onClick={this.props.closeModalHandler}>
            <span aria-hidden="true">×</span>
          </button>
        </ModalHeader>

        <ModalBody>
          {
            this.state.loading ?
              <div className="text-center">
                <div className="spinner-grow" color="primary"></div>
              </div> :
              <div>
                <Row className="pr-3 pl-3" style={{color: "grey"}}>
                  <Col sm={9}>
                    <strong>{this.state.draftInvoiceCenter.concesionario || '-'}</strong>
                  </Col>
                  <Col sm={3}>
                    <strong className="float-right">{this.state.draft.tipo_documento_pago} {this.state.draft.tipo_exencion_impuesto}</strong>
                  </Col>
                </Row>
                <hr/>
                <Form className="pr-3 pl-3">
                  <Row>
                    <Col sm={4}>
                      <FormGroup>
                        <Label for="rut" className="body-label">RUT</Label>
                        <Input type="text" name="rut" disabled
                               className="form-control-sm body-field"
                               value={this.state.draftInvoiceCenter.rut}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Label for="social_reason" className="body-label">Razón Social</Label>
                        <Input type="text" name="social_reason" disabled
                               className="form-control-sm body-field"
                               value={this.state.draftInvoiceCenter.razon_social}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Label for="contact" className="body-label">Contacto</Label>
                        <Input type="text" name="contact" disabled
                               className="form-control-sm body-field"
                               value={this.state.draftInvoiceCenter.contacto}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="address" className="body-label">Dirección</Label>
                        <Input type="text" name="address" disabled
                               className="form-control-sm body-field"
                               value={this.state.draftInvoiceCenter.direccion}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="giro" className="body-label">Giro</Label>
                        <Input type="text" name="giro" disabled
                               className="form-control-sm body-field"
                               value={this.state.draftInvoiceCenter.giro}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="oc" className="body-label">Orden de Compra</Label>
                        <Input type="text" name="oc" disabled
                               className="form-control-sm body-field"
                               value={ocNumbers.split(',').join(', ') || '-'}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="ocDate" className="body-label">Fecha Orden de Compra</Label>
                        <Input type="text" name="ocDate" disabled
                               className="form-control-sm body-field"
                               value={ocDates.split(',').join(', ') || '-'}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <hr/>
                  <Row className="pt-3 pb-3">
                    <Col sm={12}>
                      <table className="table table-striped table-bordered">
                        <thead style={{color: "grey"}}>
                          <tr>
                            <td>Producto</td>
                            <td>Cantidad</td>
                            <td>Precio</td>
                            <td>SubTotal</td>
                          </tr>
                        </thead>
                        <tbody color="grey">
                          {itemsMapped}
                        </tbody>
                      </table>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <FormGroup>
                        <Label for="amount" className="body-label">Monto Neto</Label>
                        <Input type="text" name="amount" disabled
                               className="form-control-sm body-field"
                               value={this.state.draft.total_sin_impuesto}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Label for="iva" className="body-label">Impuesto (19%)</Label>
                        <Input type="text" name="iva" disabled
                               className="form-control-sm body-field"
                               value={this.state.draft.impuesto}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Label for="total" className="body-label">Total</Label>
                        <Input type="text" name="total" disabled
                               className="form-control-sm body-field"
                               value={this.state.draft.total}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
          }
        </ModalBody>
      </Modal>
    )
  }
}

export default ShowDraft;