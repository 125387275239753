import React, {Component} from 'react';
import {Row, Col, FormGroup, Input, Button, Form} from 'reactstrap';
import BillingStatus from '../../services/BillingStatus'

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import InvoiceCenterSelect from '../../components/invoice-center-select/InvoiceCenterSelect';
import SocialReasonSelect from '../../components/ic-social-reason-select/SocialReasonSelect';

const FORMAT = 'YYYY-MM-DD';

class InvoiceCenterBillingListFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formFilters: {
                EMITED_DATE_FROM: '',
                EMITED_DATE_TO: '',
                STATUS: '',
                EXENCION_TICKET_KIND: '',
                LEGAL_ID:'',
                INVOICE_CENTER_ID: '',
                INVOICE_CENTER_REASON_SOCIAL: ''
            }
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
    }

    handleFilterChange(event) {
        let newFilterData = {};
        newFilterData[event.target.id] = event.target.value;
        newFilterData = Object.assign(this.state.formFilters, newFilterData);
        this.setState({formFilters: newFilterData});
    }

    handleDayChange(propName, selectedDay, modifiers, dayPickerInput) {
        const input = dayPickerInput.getInput();
        this.handleFilterChange({
            target:{
                id: propName,
                value:input.value.trim()
            }
        })
    }
    
    setFilters() {
        const filters = {};
        for (const filter in this.state.formFilters) {
            if (this.state.formFilters.hasOwnProperty(filter) && this.state.formFilters[filter]) {
                filters[filter] = this.state.formFilters[filter];
            }
        }
        this.props.setFiltersCallback(filters);
    }

    resetFilters() {
        const filters = {
            EMITED_DATE: '',
            EMITED_DATE_FROM: '',
            EMITED_DATE_TO: '',
            STATUS: '',
            EXENCION_TICKET_KIND: '',
            LEGAL_ID:'',
            INVOICE_CENTER_ID: '',
            INVOICE_CENTER_REASON_SOCIAL: ''
        };
        this.props.setFiltersCallback({});
        this.setState({formFilters: filters});
    }

    render() {
        return (
            <Form className={'px-2'}>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <DayPickerInput
                                onDayChange={(selectedDay, modifiers, dayPickerInput)=>{this.handleDayChange('EMITED_DATE_FROM',selectedDay, modifiers, dayPickerInput)}}
                                formatDate={formatDate}
                                format={FORMAT}
                                value={this.state.formFilters.EMITED_DATE_FROM}
                                parseDate={parseDate}
                                inputProps={{
                                    className:'form-control form-control-sm',
                                    placeholder:'Desde'
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <DayPickerInput
                                onDayChange={(selectedDay, modifiers, dayPickerInput)=>{this.handleDayChange('EMITED_DATE_TO',selectedDay, modifiers, dayPickerInput)}}
                                formatDate={formatDate}
                                format={FORMAT}
                                value={this.state.formFilters.EMITED_DATE_TO}
                                parseDate={parseDate}
                                inputProps={{
                                    className:'form-control form-control-sm',
                                    placeholder:'Hasta'
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="select" id="STATUS" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.STATUS}>
                                <option value="">Todos los estados</option>
                                {BillingStatus.getAll().map(status=>{
                                    return <option key={status.key} value={status.key}>{status.label}</option>
                                })}

                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="select" id="EXENCION_TICKET_KIND" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.EXENCION_TICKET_KIND}>
                                <option value="">Todos los tipos</option>
                                <option key="afecta" value="AFECTA">AFECTA</option>
                                <option key="exenta" value="EXENTA">EXENTA</option>

                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="text"
                                   id="LEGAL_ID"
                                   className="form-control-sm"
                                   placeholder="Folio SII"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.LEGAL_ID}>
                            </Input>
                        </FormGroup>
                    </Col>

                    <SocialReasonSelect
                        columnSize="3"
                        parentChangeHandler={this.handleFilterChange}
                        value={this.state.formFilters.INVOICE_CENTER_REASON_SOCIAL}
                        items={this.props.icBySocialReason}
                        placeholder="Todas las razones sociales"
                    />

                    <InvoiceCenterSelect
                        columnSize="3"
                        parentChangeHandler={this.handleFilterChange}
                        value={this.state.formFilters.INVOICE_CENTER_ID}
                        socialReason={this.state.formFilters.INVOICE_CENTER_REASON_SOCIAL}
                        items={this.props.invoiceCenters}
                    />

                    <Col md={2} className="ml-auto filter-button">
                        <Button className="btn btn-sm btn-primary-darker btn btn-secondary mr-2" onClick={this.setFilters}>Aplicar</Button>
                        <Button className="btn btn-sm btn-primary btn" onClick={this.resetFilters}>Limpiar</Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default InvoiceCenterBillingListFilter;
