import React from 'react';

import logo from '../../theme/assets/logo_af_pro.svg';

const Footer = () => {
    return (
        <footer className={'footer text-center bg-primary-darker'}>
            <div className="container">
                <img className="footer-logo" src={logo} alt="Logo Autofact Pro" style={{width:200, height:100}}/>
                <p>Todos los derechos reservados &copy; - Contacto: soporte@autofactpro.com - v1.0 <br/> Contacto: soporte@autofactpro.com</p>
            </div>
        </footer>
    )
}

export default Footer;
