import React, {Component} from 'react';
import BackendApiService from "../../services/BackendApiService";

import InvoiceCenterBillingSummary from "../invoice-center-billing-summary/InvoiceCenterBillingSummary";
import _ from 'lodash'

class InvoiceCenterBillingSummaryContainer extends Component {
    constructor(props) {
        super(props);
        this.state     = {
            full_pending: 0,
            full_amount_pending: 0,
            range_pending: 0,
            range_amount_pending: 0,
            in_filter: false
        };
        this.fetchData = this.fetchData.bind(this);
    }

    async fetchData(params) {
        let in_filter = false;
        const filters = ['EMITED_DATE_FROM','EMITED_DATE_TO','STATUS','EXENCION_TICKET_KIND','LEGAL_ID'];
        try{
            const keys = Object.keys(JSON.parse(params.where));
            const filterApplied = _.intersection(filters, keys);
            in_filter = filterApplied.length > 0
        }catch (e){

        }
        try {

            const invoiceCenterPendingSummary = await BackendApiService.getInvoiceCenterBillingPendingSummary(params);
            console.log(invoiceCenterPendingSummary);
            this.setState({
                full_pending:invoiceCenterPendingSummary.fullResult.cantidad,
                full_amount_pending:invoiceCenterPendingSummary.fullResult.monto,
                range_pending:invoiceCenterPendingSummary.filterResult.cantidad,
                range_amount_pending:invoiceCenterPendingSummary.filterResult.monto,
                in_filter
            });

        } catch (e) {
            console.log("ocurrio un error al recuperar el resumen de cierres")
            this.setState({
                full_pending:0,
                full_amount_pending:0,
                range_pending:0,
                range_amount_pending:0,
                in_filter
            });
        }
    }

    render() {
        return (
            <div>
                <InvoiceCenterBillingSummary
                    full_pending={this.state.full_pending}
                    full_amount_pending={this.state.full_amount_pending}
                    range_pending={this.state.range_pending}
                    range_amount_pending={this.state.range_amount_pending}
                    in_filter={this.state.in_filter}
                />
            </div>
        );
    }
}

export default InvoiceCenterBillingSummaryContainer;
