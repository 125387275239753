import React, { Component } from "react";
import { Collapse, Nav, NavItem, NavLink, Button, Alert } from "reactstrap";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';

import BackendApiService from "../../services/BackendApiService";
import SaleNotesListContainer from "../../components/sale-notes/SaleNotesListContainer";
import SaleNotesListFilter from "../../components/sale-notes/SaleNotesListFilter";
import SaleNotesSummaryContainer from "../../components/sale-notes/SaleNotesSummaryContainer";
import FilterIcon from '../../theme/assets/filter_conces.svg';
import {buildSaleNoteFilters} from '../../helpers/filterBuilder';

class SaleNotesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtersCollapsed: false,
      filters: {},
      userData: props.userData,
      invoiceCenters: [],
      icBySocialReason: []
    };
    this.toggleFilters = this.toggleFilters.bind(this);
    this.setFiltersCallback = this.setFiltersCallback.bind(this);
    this.currentTableRef = React.createRef();
    this.filterComponentRef = React.createRef();
    this.summaryComponentRef = React.createRef();
  }

  toggleFilters() {
    this.setState({ filtersCollapsed: !this.state.filtersCollapsed });
  }

  buildFilters(filters = null) {
    const where = filters || this.state.filters;
    return buildSaleNoteFilters(this.props.userData, where);
  }

  setFiltersCallback(filters) {
    this.setState({ filters });
    const buildedFilters = this.buildFilters(filters);
    this.currentTableRef.current.refreshFilters(buildedFilters);
    this.summaryComponentRef.current.fetchData(buildedFilters);
  }

  componentDidMount() {
    const userData = this.state.userData.afpr_us_data;
    if (userData) {
      const buildedFilters = this.buildFilters();
      this.summaryComponentRef.current.fetchData(buildedFilters);

      this.getInvoiceCenters(userData.idgrupo_empresa)
        .then(response => {
          let icBySocialReason = [];
          if (response.data && response.data.length) {
            icBySocialReason = this._buildICBySocialReason(response.data);
          }
          this.setState({invoiceCenters: response.data, icBySocialReason})
        }).catch(error => {
        console.log(error);
      })
    }
  }

  _buildICBySocialReason(invoiceCenters) {
    const groupBySocialReason = _.groupBy(invoiceCenters, ic => {return (ic.secondName.trim())});
    const keys = Object.keys(groupBySocialReason); // ic social reasons
    const result = [];
    keys.forEach(key => {
      result.push({id: key, name: key});
    });
    return result;
  }

  async getInvoiceCenters(companyId) {
    try {
      return await BackendApiService.getInvoiceCenters(companyId);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div>
        {this.state.userData.afpr_us_data ? (
          <div>
            <Alert className="invoice-info" color="warning">
              La actualización de pagos se realiza en un intervalo de 1 a 3 días desde recibido el comprobante respectivo
            </Alert>
            <div className="page-title">
              <FontAwesomeIcon
                icon="file-invoice"
                className="icon-title"
                alt="Icono de Facturas"
              />
              <h2 className="text-title">Cierres</h2>
            </div>
            <div className={"pb-4"}>
              <SaleNotesSummaryContainer
                ref={this.summaryComponentRef}
              />
            </div>
            <Nav tabs className="tabs-dark">
              <NavItem key={`tab-nav-item-${1}`}>
                <NavLink
                  key={`tab-nav-${1}`}
                  className={""}
                >
                  {"Cierres"}
                </NavLink>
              </NavItem>
              <NavItem className="nav-button">
                <Button
                  className="btn btn-sm btn-primary-dark"
                  onClick={this.toggleFilters}
                >
                  <img
                    className="filter-icon"
                    src={FilterIcon}
                    alt="Filter Icon"
                  />{" "}
                  Filtros
                </Button>
              </NavItem>
            </Nav>
            <div>
              <Collapse
                isOpen={this.state.filtersCollapsed}
              >
                <div className="filter-form-wrapper">
                  <SaleNotesListFilter
                    ref={this.filterComponentRef}
                    setFiltersCallback={this.setFiltersCallback}
                    invoiceCenters={this.state.invoiceCenters}
                    icBySocialReason={this.state.icBySocialReason}
                  />
                </div>
              </Collapse>
            </div>
            <SaleNotesListContainer
              id={"invoice-center-billing-list"}
              ref={this.currentTableRef}
              filters={this.buildFilters()}
              className="tab-pane"
            />
          </div>
        ) : (
          <Redirect to="/login" />
        )}
      </div>
    );
  }
}

export default connect(store => ({
  userData: store.app.userData
}))(SaleNotesList);
