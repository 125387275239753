const PENDING = 'PENDIENTE';
const PAID    = 'PAGADO';
const LATE    = 'ATRASADO';
const TO_BE_CONFIRMED = 'POR_CONFIRMAR';

export default {
    PENDING,
    PAID,
    LATE,
    TO_BE_CONFIRMED,
    getName(status){
        const statusObject = this.getAll().find(item=>{
            return status === item.key;
        });
        if(statusObject){
            return statusObject.label.toUpperCase();
        }
        return '';
    },
    getAll(){
        return [
            {
                key: PENDING,
                label: 'Pendiente'
            },
            {
                key: PAID,
                label: 'Pagado'
            },
            {
                key: LATE,
                label: 'Atrasado'
            },
            {
                key: TO_BE_CONFIRMED,
                label: '* Pagado *'
            }
        ]
    }
};
