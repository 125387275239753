import React, {Component} from 'react';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import Pagination from './pagination/Pagination';

class DataTable extends Component {
    constructor(props) {
        super(props);

        this.defaultPageRowsCount = 5;
        this.maxPagesRowsCount = 25;
        this.state = {
            data: [],
            pages: null,
            loading: false
        };

        this.fetchData = this.fetchData.bind(this);
    }

    extractPagesFromPageData(extra) {
        return Math.ceil(extra.recordsTotal / this.maxPagesRowsCount);
    }

    fetchData(state, instance) {
        //TODO build params
        const params = {
            per_page: state.pageSize,
            page: state.page + 1
        };
        this.setState({ loading: true });
        if (this.props.fetchDataCallback) {
            this.props.fetchDataCallback(params)
                .then(response => {
                    this.setState({
                        data: response.data,
                        pages: this.extractPagesFromPageData(response.extra),
                        loading: false
                    });
                }).catch(error=>{
                console.log(error);
            });
        }
    }
    

    reloadData() {
        this.refReactTable.fireFetchData();
    }

    render() {
        let getTrProps = ()=> {
            return {style: {}}
        };
        if(typeof this.props.getTrProps === 'function'){
            getTrProps = this.props.getTrProps;
        }
        return (
            <div>
                <ReactTable
                    ref={(refReactTable) => {this.refReactTable = refReactTable;}}
                    PaginationComponent={Pagination}
                    manual
                    sortable={false}
                    columns={this.props.columns}
                    data={this.state.data}
                    pages={this.state.pages}
                    loading={this.state.loading}
                    defaultPageSize={this.maxPagesRowsCount}
                    minRows={this.defaultPageRowsCount}
                    onFetchData={this.fetchData}
                    loadingText={'Cargando datos...'}
                    noDataText={'No hay datos que mostrar'}
                    className="-striped -highlight pro-data-table"
                    getTrProps={getTrProps}
                />
            </div>
        );
    }
}

export default DataTable;
