import React, {Component} from 'react';
import moneyFormat from '../../helpers/MoneyFormat'

class SaleNotesSummary extends Component {   
    render() {
        return (
            <div className={`invoice-center-billing-summary-wrapper`}>
                <div>Cantidad de cierres pendientes: <strong>{this.props.full_pending || 0}</strong></div>
                <div>Monto de cierres pendientes: <strong>${moneyFormat(this.props.full_amount_pending || 0)}</strong></div>
                {/* {this.props.in_filter &&  <div>Cantidad de facturas pendientes en rango : <strong>{this.props.range_pending}</strong></div>} */}
                {/* {this.props.in_filter &&   <div>Monto de facturas pendientes en rango : <strong>${moneyFormat(this.props.range_amount_pending || 0)}</strong></div>} */}
            </div>
        );
    }
}

export default SaleNotesSummary;
