import React, {Component} from 'react';
import BackendApiService from "../../services/BackendApiService";

import InvoiceCenterClosureSummary from "../invoice-center-closure-summary/InvoiceCenterClosureSummary";
import _ from 'lodash'

class InvoiceCenterClosureSummaryContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            full_pending:0,
            full_amount_pending:0,
            range_pending:0,
            range_amount_pending:0,
            in_filter:false
        };
        this.fetchData                        = this.fetchData.bind(this);
    }

    async fetchData(params) {
        let in_filter = false;

        const filters = ['EMITED_DATE','DATE_START','DATE_END','TYPE','STATUS'];
        try{
            const keys = Object.keys(JSON.parse(params.where));
            const filterApplied = _.intersection(filters, keys);
            in_filter = filterApplied.length > 0
        }catch (e){

        }
        try{
            const invoiceCenterPendingSummary = await BackendApiService.getInvoiceCenterClosurePendingSummary(params);
            this.setState({
                full_pending:invoiceCenterPendingSummary.fullResult.cantidad,
                full_amount_pending:invoiceCenterPendingSummary.fullResult.monto,
                range_pending:invoiceCenterPendingSummary.filterResult.cantidad,
                range_amount_pending:invoiceCenterPendingSummary.filterResult.monto,
                in_filter
            });

        }catch (e){
            console.log("ocurrio un error al recuperar el resumen de cierres")
            this.setState({
                full_pending:0,
                full_amount_pending:0,
                range_pending:0,
                range_amount_pending:0,
                in_filter
            });
        }
    }

    render() {
        return (
            <div>
                <InvoiceCenterClosureSummary
                    full_pending={this.state.full_pending}
                    full_amount_pending={this.state.full_amount_pending}
                    range_pending={this.state.range_pending}
                    range_amount_pending={this.state.range_amount_pending}
                    in_filter={this.state.in_filter}
                />
            </div>
        );
    }
}

export default InvoiceCenterClosureSummaryContainer;
