import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button } from 'reactstrap';
import moment from 'moment';

import DataTable from '../data-table/DataTable';

class InvoiceCenterContactList extends Component {
    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();

        this.state = {}
    }

    reloadData = () => {
        this.dataTableRef.current.reloadData();
    }

    renderUpdateButtons(row){
        return <Button className="btn btn-sm btn-primary-darker btn btn-secondary mr-2" onClick={() => this.showModal(row.id)}>Actualizar</Button>
    }

    showModal(idInvoiceCenter){
        this.props.showUpdateModal(idInvoiceCenter)
    }

    renderLastUpdate(row){
        const color = row.requireUpdateContact === 'true' ? 'red' : '';
        return <span style={{color: color}}> {moment(row.lastUpdateContact).format('DD/MM/YYYY')} </span>;
    }

    render(){
        const columns = [
            {
                Header: 'Rut',
                accessor: 'identifier',
                headerClassName: 'wordwrap',
            },
            {
                Header: 'Razón social',
                accessor: 'secondName',
                headerClassName: 'wordwrap',
            },
            {
                Header: 'Sucursal',
                accessor: 'name',
                headerClassName: 'wordwrap',
            },
            {
                Header: 'Última actualización',
                headerClassName: 'wordwrap',
                Cell: row => {
                    return this.renderLastUpdate(row.original)
                }
            },
            {
                Header: 'Acciones',
                headerClassName: 'wordwrap',
                Cell: row => {
                    return this.renderUpdateButtons(row.original)
                }
            }
        ]

        return(
            <DataTable
                    ref={this.dataTableRef}
                    key={`data-table-${this.props.id}`}
                    id={this.props.id}
                    columns={columns} {...this.props}
                />
        );
    }
}

export default connect(store => ({
    userData: store.app.userData
}),null, null,  { withRef: true })(InvoiceCenterContactList);