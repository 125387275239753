import React, {Component} from 'react';
import { Col, FormGroup, Input, Label} from 'reactstrap';
import moneyFormat from '../../helpers/MoneyFormat'

class BankTransactionsFund extends Component {


    handleFilterChange = (event) => {
       const accountSelected = this.props.accounts.find(account=> {
           return parseInt(event.target.value) === parseInt(account.id)
       });
       this.props.changeAccount(accountSelected)
    }
    render() {
        if (this.props.accounts.length > 1) {
            return <Col md={4} className="bank-account-fund mb-5 d-flex py-2 justify-content-between">
                <Col md={8} className="pl-0">
                    <FormGroup>
                        <Label for="exampleSelect">Cuenta</Label>
                        <Input type="select" className="form-control-sm"
                               onChange={this.handleFilterChange}
                               value={this.props.accountSelected.id}>
                            {this.props.accounts.map(account=> {
                                return <option key={account.id} value={account.id}>{account.name}</option>
                            })}

                        </Input>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <div className="label">Fondo total</div>
                    <div className="text-primary-dark">${moneyFormat(this.props.accountSelected.fund || 0)}</div>
                </Col>
            </Col>;
        }
        return  <Col md={2} className="bank-account-fund mb-5  d-flex py-2 flex-column">
                <div className="label">Fondo total</div>
                <div className="text-primary-dark">${moneyFormat(this.props.accountSelected.fund || 0)}</div>
        </Col>
    }
}

export default BankTransactionsFund;
