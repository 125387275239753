import {endpoints} from '../config';

const API_BASE_URL = endpoints.backendApi.url;

const defaultFetchParams = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    credentials: 'include'
};

class BackendApiService {

    static async processApiResponseSingle(apiResponse) {
        if (![200, 201].includes(apiResponse.status)) {
            return false;
        }
        const fullResponse = await apiResponse.clone().json();
        if (fullResponse.data)
            return fullResponse.data
        return fullResponse;
    }

    static async processApiResponseList(apiResponse) {
        if (apiResponse.status !== 200) {
            return {
                data: [],
                extra: {
                    recordsTotal: 0,
                    recordsFiltered: 0
                }
            };
        }
        return await apiResponse.json();
    }

    static objToQueryString(obj) {
        const keyValuePairs = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
            }
        }
        return keyValuePairs.join('&');
    }

    static async getSessionData() {
        const apiResponse = await fetch(`${API_BASE_URL}session`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async getInvoiceCenterClosureList(params) {
        const apiResponse = await fetch(`${API_BASE_URL}collections/invoice-center-closure?${this.objToQueryString(params)}`, defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }
    
    static async getInvoiceCenterClosurePendingSummary(params) {
        const apiResponse = await fetch(`${API_BASE_URL}collections/invoice-center-closure-summary-pending?${this.objToQueryString(params)}`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    } 
    
    static async getInvoiceCenterBillingPendingSummary(params) {
        const apiResponse = await fetch(`${API_BASE_URL}billing-reports/payment-documents-summary-pending?${this.objToQueryString(params)}`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async payInvoiceCenterClosure(invoiceCenterId, params) {
        const apiResponse = await fetch(`${API_BASE_URL}collections/invoice-center-closure/${invoiceCenterId}/pay`, {
            ...defaultFetchParams,
            method: 'PUT',
            body: JSON.stringify(params)
        });
        return await this.processApiResponseSingle(apiResponse);
    }
    static async getPaymentDocuments(params) {
        const apiResponse = await fetch(`${API_BASE_URL}billing-reports/payment-documents?${this.objToQueryString(params)}`,defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }
    static async findPurchaseOrdersByDoc(paymentDocId) {
      const apiResponse = await fetch(`${API_BASE_URL}billing-reports/payment-documents/${paymentDocId}/purchase-orders`,defaultFetchParams);
      return await this.processApiResponseSingle(apiResponse);
    }
    static async getConsolidateDetailsZip(consolidateID) {
        const apiResponse = await fetch(`${API_BASE_URL}billing-reports/consolidates/${consolidateID}/details-zip`,defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }
    static async getConsolidateDetails(consolidateID) {
        const apiResponse = await fetch(`${API_BASE_URL}billing-reports/consolidates/${consolidateID}/details`,defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }
    static async getAccountActivesWithCostCenters(costCenterIds) {
        const params = {
            status: 1,
            cost_center_id:costCenterIds
        }
        const apiResponse = await fetch(`${API_BASE_URL}bank/accounts?${this.objToQueryString(params)}`,defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }
    static async getAccountTransactions(accountId, params) {
        const apiResponse = await fetch(`${API_BASE_URL}bank/accounts/${accountId}/transactions-with-transferences?${this.objToQueryString(params)}`,defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }
    static async getCostCenters(companyId) {
        const params = {
            enterpriseGroupId:companyId
        }
        const apiResponse = await fetch(`${API_BASE_URL}billing-service/billing-admin/api/v1/costCenters/list?${this.objToQueryString(params)}`,defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }
    static async getInvoiceCenters(companyId) {
        const params = { idGrupoEmpresa: companyId };
        const apiResponse = await fetch(`${API_BASE_URL}billing-service/billing-admin/api/v1/invoiceCenters?${this.objToQueryString(params)}`,defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }

    static async getTransferences(params) {
        const apiResponse = await fetch(`${API_BASE_URL}tranferences/transferences?${this.objToQueryString(params)}`, defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    } 
    
    static async updateTransferencePriority(code, priority) {
        const apiResponse = await fetch(`${API_BASE_URL}tranferences/transferences/${code}/set-priority`, {
            ...defaultFetchParams,
            method: 'POST',
            body: JSON.stringify({priority})
        });
        return await this.processApiResponseList(apiResponse);
    }
    
    static async doLogout(domain) {
        const apiResponse = await fetch(`${API_BASE_URL}logout?domain=${domain}`, defaultFetchParams);
        return await this.processApiResponseSingle(apiResponse);
    }

      /**
     * Call collection API to upload files to S3
     * @param {} params -> path,fileName,fileDataBase64
     */
    static async uploadToS3(params) {
        const url = `${API_BASE_URL}upload-s3`;
        const requestParams = {
          ...defaultFetchParams, method: 'POST', body: JSON.stringify(params)
        };
        const apiResponse = await fetch(url, requestParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    /**
     * Call collection API to upload Purchase Order to S3
     * @param {} params -> path,fileName,fileDataBase64
     */
    static async uploadPurchaseOrder(params) {
        const url = `${API_BASE_URL}upload-purchase-order`;
        const requestParams = {
          ...defaultFetchParams, method: 'POST', body: JSON.stringify(params)
        };
        const apiResponse = await fetch(url, requestParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    /** *
    * Call billing-invoicer API to create Purchase Order
    * @param {*} params -> deliveredDate,legalIdentifier,consolidateId,invoiceCenterId,paymentDocId,urlPdf
    */
    static async assignPurchaseOrder(params) {
        const url = `${API_BASE_URL}billing-invoicer/paymentDocuments/${params.paymentDocId}/purchaseOrder`;
        const requestParams = {
          ...defaultFetchParams, method: 'POST', body: JSON.stringify(params)
        };
        const apiResponse = await fetch(url, requestParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    static async findDraftDocumentData(params) {
      const docDataUrl = `${API_BASE_URL}billing-reports/payment-documents/${params.paymentDocId}`;
      const itemsDataUrl = `${API_BASE_URL}billing-reports/payment-documents/${params.paymentDocId}/items`;
      const icDataUrl = `${API_BASE_URL}billing-reports/payment-documents/${params.paymentDocId}/invoice_center`;

      const responseAll = await Promise.all([
        fetch(docDataUrl, defaultFetchParams),
        fetch(itemsDataUrl, defaultFetchParams),
        fetch(icDataUrl, defaultFetchParams)
      ]);
      const processResponsesPromises = [
        this.processApiResponseSingle(responseAll[0]), // draft data
        this.processApiResponseSingle(responseAll[1]), // items data
        this.processApiResponseSingle(responseAll[2]), // invoice center data
      ]
      const processedResponses = await Promise.all(processResponsesPromises);
      return {
        draft: processedResponses[0],
        items: processedResponses[1],
        draftInvoiceCenter: processedResponses[2]
      }
    }

    /**
     * Call billing-notifications API to send email about notified payment
     * @param {*} params -> paymentDate, paymentBankAccount, paymentType, amount, comments, invoicesData, socialReason
     */
    static async sendEmailPaymentNotification(params) {
        const url = `${API_BASE_URL}billing-notifications/documents/payment-notification`;
        const requestParams = {
            ...defaultFetchParams, method: 'POST', body: JSON.stringify(params)
          };
        const apiResponse = await fetch(url, requestParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    /**
     * Call billing-notifications API to send email when purchase order is uploaded
     * @param {*} params -> fromEmail, ocNumber, ocDate, paymentDocId, socialReason, ocUrl=''
    */
    static async sendEmailOCNotification(params) {
        const url = `${API_BASE_URL}billing-notifications/documents/purchase-order-notification`;
        const requestParams = {
            ...defaultFetchParams, method: 'POST', body: JSON.stringify(params)
        };
        const apiResponse = await fetch(url, requestParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    /**
     * Call billing-invoicer API to change payment docs status
     * @param {*} params -> paymentDocsIds, status
     */
    static async changePaymentDocStatus(params) {
        const url = `${API_BASE_URL}billing-invoicer/paymentDocuments/changePaymentDocumentsStatus/${params.status}`;
        const requestParams = {
          ...defaultFetchParams, method: 'POST', body: JSON.stringify(params.paymentDocsIds)
        };
        const apiResponse = await fetch(url, requestParams);
        return await this.processApiResponseSingle(apiResponse);
    }

    /**
    * Call cobranza API to change closures status
    * @param {*} params -> closuresIds, status
    */
    static async changeClosuresStatus(params) {
        const url = `${API_BASE_URL}collections/invoice-center-closure`;
        const updateParams = {
            invoice_center_closure_ids: params.closuresIds,
            status: params.status
        };
        const requestParams = {
            ...defaultFetchParams, method: 'PUT', body: JSON.stringify(updateParams)
        }
        const apiResponse = await fetch(url, requestParams);
        return await this.processApiResponseSingle(apiResponse);
    }
    /**
    * Call cobranza API to send email about notified payment
    * @param {*} params -> paymentDate, paymentBankAccount, amount, closuresData, socialReason
    */
    static async sendEmailPaymentNotificationClosure(params) {
        const url = `${API_BASE_URL}collections/invoice-center-closure/payment-notification`;
        const requestParams = {
        ...defaultFetchParams, method: 'POST', body: JSON.stringify(params)
        };
        const apiResponse = await fetch(url, requestParams);
        return await this.processApiResponseSingle(apiResponse);
    }

  /**
   * Call billing-invoicer API to delete purchase order
   * @param ocId
   */
  static async deletePurchaseOrder(ocId) {
    const url = `${API_BASE_URL}billing-invoicer/paymentDocuments/${ocId}/purchaseOrder`;
    const requestParams = {
      ...defaultFetchParams, method: 'DELETE'
    };
    const apiResponse = await fetch(url, requestParams);
    return await this.processApiResponseSingle(apiResponse);
  }

    /**
     * Call API to update invoice center contact
     * @param userCode 
     * @param invoiceCenterId 
     * @param telefono 
     * @param email 
     */
    static async updateInvoiceCenterContact(userId, invoiceCenterId, telefono, email) {
        const paramsBody = {
            notificator: "email",
            targets: email
        };

        const paramsQuery = {
            idOwner: userId
        };

        const url = `${API_BASE_URL}billing-service/billing-admin/api/v1/invoiceCenters/${invoiceCenterId}/cobranza-notification?${this.objToQueryString(paramsQuery)}`;

        const requestParams = {
            ...defaultFetchParams, method: 'PUT', body: JSON.stringify(paramsBody)
        };
        const apiResponse = await fetch(url, requestParams);
        
        return await this.processApiResponseList(apiResponse);
    }

    /**
     * Call API to update invoice center contact
     * @param idInvoiceCenter 
     * @param type 
     * @param value 
     * @param name 
     * @param notifySlack
     * @param notifyMessage
     */
    static async updateInvoiceCenterContactScope(idInvoiceCenter, invoiceCenterContactIds, type, value, name, cargo = '', notifySlack = false, notifyMessage = '') {
        const params = {
            notifySlack: notifySlack,
            notifyMessage: notifyMessage,
            name: name,
            contact: value,
            position: cargo,
            type: type,
            invoiceCenterContactIds,
            scope:"COLLECTION"
        }

        const requestParams = {
            ...defaultFetchParams, method: 'POST', body: JSON.stringify(params)
        };

        const apiResponse = await fetch(`${API_BASE_URL}billing-reports/invoice-center/${idInvoiceCenter}/contact/save`, requestParams);
        return await this.processApiResponseList(apiResponse);
    }

    /**
     * Call API to update invoice center contact
     * @param companyId 
     * @param isGroupCompany 
     */
    static async getInvoiceCentersExtraData(companyId, isGroupCompany = false) {
        let params = {}
        if (isGroupCompany){
            params = { idGrupoEmpresa: companyId, includeExtraInfo: true };
        } else {
            params = { idEmpresa: companyId, includeExtraInfo: true };
        }
        const apiResponse = await fetch(`${API_BASE_URL}billing-service/billing-admin/api/v1/invoiceCenters?${this.objToQueryString(params)}`,defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }

    /**
     * Call API get update invoice center contacts
     * @param IdInvoiceCenter 
     */
    static async getInvoiceCenterContacts(IdInvoiceCenter) {
        const apiResponse = await fetch(`${API_BASE_URL}billing-reports/invoice-center/${IdInvoiceCenter}/contact`,defaultFetchParams);
        return await this.processApiResponseList(apiResponse);
    }
}

export default BackendApiService;
