/**
 * Created by Ale on 5/13/2019.
 */
import React, {Component} from 'react';
import {Col, FormGroup, Input} from 'reactstrap';

class SocialReasonSelect extends Component {

    render() {
        return (
            <Col md={this.props.columnSize}>
                <FormGroup>
                    <Input type="select"
                           id="INVOICE_CENTER_REASON_SOCIAL"
                           className="form-control-sm"
                           onChange={this.props.parentChangeHandler}
                           value={this.props.value}>
                        <option value="">Todas las razones sociales</option>
                        {
                            this.props.items.length ?
                                this.props.items.map(ic =>{
                                    return <option key={ic.id} value={ic.id}>{ic.name}</option>
                                }) : null
                        }
                    </Input>
                </FormGroup>
            </Col>
        )
    }
}

export default SocialReasonSelect;