import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PaymentError extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: props.userData,
    };
  }

  render() {
    return (
      <div>
        {this.state.userData.afpr_us_data ? (
          <div className="payment-success">
            <div className="page-title">
              <FontAwesomeIcon
                icon="file-invoice"
                className="icon-title"
                alt="Icono de Facturas"
              />
              <h2 className="text-title">Error en el pago de documentos</h2>
            </div>
            <div className="main-container">
              <h2 className="text-title"><b>Lo sentimos,</b> hubo un problema en tu transacción</h2>
              <p className="text">Tu pago no ha podido completarse exitosamente, por favor intenta más tarde.</p>
              <p className="text-center">
                <a className="btn btn-bg-orange-white back" href='/facturas'>Intentar nuevamente</a>
              </p>
            </div>
          </div>
        ) : (
            <Redirect to="/login" />
          )}
      </div>
    );
  }
}

export default connect(store => ({
  userData: store.app.userData
}))(PaymentError);
