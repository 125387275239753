const PENDING = 'DELIVERED';
const PAID    = 'PAID';
const LATE    = 'LATE';
const CREATED   = 'CREATED';
const TO_CONFIRM   = 'TO_CONFIRM';
export default {
    PENDING,
    PAID,
    LATE,
    CREATED,
    TO_CONFIRM,
    getName(status){
        const statusObject = this.getAll().find(item=>{
            return status === item.key;
        })
        if(statusObject){
            return statusObject.label.toUpperCase();
        }else{
            return '';
        }
    },
    getAll(){

        return [
            {
                key: CREATED,
                label: 'Borrador',
            },
            {
                key: PENDING,
                label: 'Pendiente'
            },
            {
                key: PAID,
                label: 'Pagado'
            }, {
                key: LATE,
                label: 'Atrasado'
            },
            {
                key: TO_CONFIRM,
                label: 'Por confirmar'
            }
        ]
    }
};
