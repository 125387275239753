import React, {Component} from 'react';

class Forbidden extends Component {

    render() {
        return (
            <div>No tiene acceso a esta sección del sitio</div>
        );
    }

}

export default Forbidden;
