import React, {Component} from 'react';
import {Row, Col, FormGroup, Input, Button, Form} from 'reactstrap';
import InvoiceCenterClosureStatus from '../../services/InvoiceCenterClosureStatus';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';

const FORMAT = 'YYYY-MM-DD';

class InvoiceCenterClosureListFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formFilters: {
                EMITED_DATE: '',
                DATE_START: '',
                DATE_END: '',
                TYPE: '',
                STATUS: ''
            }
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
    }

    handleFilterChange(event) {
        let newFilterData = {};
        newFilterData[event.target.id] = event.target.value;
        newFilterData = Object.assign(this.state.formFilters, newFilterData);
        this.setState({formFilters: newFilterData});
    }
    handleDayChange(propName, selectedDay, modifiers, dayPickerInput) {
        const input = dayPickerInput.getInput();
        this.handleFilterChange({
            target:{
                id: propName,
                value:input.value.trim()
            }
        })
    }
    setFilters() {
        const filters = {};
        for (const filter in this.state.formFilters) {
            if (this.state.formFilters.hasOwnProperty(filter) && this.state.formFilters[filter]) {
                filters[filter] = this.state.formFilters[filter];
            }
        }
        this.props.setFiltersCallback(filters);
    }

    resetFilters() {
        const filters = {
            EMITED_DATE: '',
            DATE_START: '',
            DATE_END: '',
            TYPE: '',
            STATUS: ''
        };
        this.props.setFiltersCallback({});
        this.setState({formFilters: filters});
    }

    render() {
        return (
            <Form className={'px-2'}>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <DayPickerInput
                                onDayChange={(selectedDay, modifiers, dayPickerInput)=>{this.handleDayChange('EMITED_DATE',selectedDay, modifiers, dayPickerInput)}}
                                formatDate={formatDate}
                                format={FORMAT}
                                value={this.state.formFilters.EMITED_DATE}
                                parseDate={parseDate}
                                inputProps={{
                                    className:'form-control form-control-sm',
                                    placeholder:'Fecha de emisión'
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <DayPickerInput
                                onDayChange={(selectedDay, modifiers, dayPickerInput)=>{this.handleDayChange('DATE_START',selectedDay, modifiers, dayPickerInput)}}
                                formatDate={formatDate}
                                format={FORMAT}
                                value={this.state.formFilters.DATE_START}
                                parseDate={parseDate}
                                inputProps={{
                                    className:'form-control form-control-sm',
                                    placeholder:'Desde'
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <DayPickerInput
                                onDayChange={(selectedDay, modifiers, dayPickerInput)=>{this.handleDayChange('DATE_END',selectedDay, modifiers, dayPickerInput)}}
                                formatDate={formatDate}
                                format={FORMAT}
                                value={this.state.formFilters.DATE_END}
                                parseDate={parseDate}
                                inputProps={{
                                    className:'form-control form-control-sm',
                                    placeholder:'Hasta'
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="select" id="TYPE" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.TYPE}>
                                <option value="">Tipo</option>
                                    <option key="Transferencia" value="TRANSFERENCIA">Transferencia</option>
                                    <option key="limpieza" value="LIMPIEZA">Limpieza</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="select" id="STATUS" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.STATUS}>
                                <option key="status-all" value="">Todos los estados</option>
                                {InvoiceCenterClosureStatus.getAll().map(status=>{
                                    return <option key={status.key} value={status.key}>{status.label}</option>
                                })}

                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={2} className="ml-auto filter-button">
                        <Button className="btn btn-sm btn-primary-darker btn btn-secondary mr-2" onClick={this.setFilters}>Aplicar</Button>
                        <Button className="btn btn-sm btn-primary" onClick={this.resetFilters}>Limpiar</Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default InvoiceCenterClosureListFilter;
