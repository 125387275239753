import React, {Component} from 'react';

class NotFound extends Component {

    render() {
        return (
            <div>Not found page</div>
        );
    }

}

export default NotFound;
