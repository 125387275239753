import React, { Component } from "react";
import { Nav, NavItem, NavLink,  Alert} from "reactstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InvoiceCenterContactListContainer from "../../components/invoice-center-contact-list-container/InvoiceCenterContactListContainer";
import { Redirect } from "react-router";
import { replace } from 'lodash';
import { endpoints } from '../../config';

class InvoiceCenterContactList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            userData: this.props.userData,
        }
    }

    render(){
        
        return (
            <div>
                {this.state.userData.afpr_us_data ? (
                    <div>
                        <Alert className="invoice-info" color="warning">
                            <i>Le pedimos <strong>encarecidamente</strong> actualizar la información de los centros de facturación. Una vez realizado puede retornar a la plataforma mediate el siguiente <a href={endpoints.autofactProApi.base}>link</a></i>
                        </Alert>

                        <div className="page-title">
                            <FontAwesomeIcon
                                icon="file-invoice"
                                className="icon-title"
                                alt="Icono de Facturas"
                            />
                            <h2 className="text-title">Datos facturación</h2>
                        </div>

                        <Nav tabs className="tabs-dark">
                            <NavItem key={`tab-nav-item-${1}`}>
                                <NavLink
                                key={`tab-nav-${1}`}
                                className={""}
                                >
                                {"Centros de facturación"}
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <InvoiceCenterContactListContainer userData={this.props.userData} location={this.props.location} />
                    </div>
                ) : (
                    
                    <Redirect to={`/login?updateContactData=true${replace(this.props.location.search,'?','&')}`} />
                )}
            </div>
        );
    } 
}

export default connect(store => ({
    userData: store.app.userData
  }))(InvoiceCenterContactList);
  