/**
 * Created by Ale on 3/26/2019.
 */
import React, {Component} from 'react';
import {Col, FormGroup, Input} from 'reactstrap';

class InvoiceCenterSelect extends Component {

    render() {
        let invoiceCenters = this.props.items || [];
        let options = invoiceCenters.map(ic =>{
            return <option key={ic.id} value={ic.id}>{ic.name}</option>
        });
        if (this.props.socialReason) {
            options = [];
            let filtered = invoiceCenters.filter(ic => ic.secondName === this.props.socialReason);
            filtered.forEach(ic => options.push(<option key={ic.id} value={ic.id}>{ic.name}</option>));
        }
        return (
            <Col md={this.props.columnSize}>
                <FormGroup>
                    <Input type="select"
                           id="INVOICE_CENTER_ID"
                           className="form-control-sm"
                           onChange={this.props.parentChangeHandler}
                           value={this.props.value}>
                        <option value="">Todas las sucursales</option>
                        {
                            options
                        }
                    </Input>
                </FormGroup>
            </Col>
        )
    }
}

export default InvoiceCenterSelect;

