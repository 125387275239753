import React, {Component} from 'react';

import moneyFormat from '../../helpers/MoneyFormat'


class InvoiceCenterClosureSummary extends Component {   
    
    render() {
        return (
            <div className={`invoice-center-closure-summary-wrapper`}>
                <div>Cantidad de cierres pendientes : <strong>{this.props.full_pending}</strong></div>
                <div>Monto de cierres pendientes : <strong>${moneyFormat(this.props.full_amount_pending || 0)}</strong></div>
                {this.props.in_filter &&<div>Cantidad de cierres pendientes en rango : <strong>{this.props.range_pending}</strong></div>}
                {this.props.in_filter && <div>Monto de cierres pendientes en rango : <strong>${moneyFormat(this.props.range_amount_pending || 0)}</strong></div>}
            </div>
        );
    }
}

export default InvoiceCenterClosureSummary;
