import { configVars } from '../config';
import AuthService from '../services/AuthService';
import Permission from '../services/Permissions';

import BillingStatus from '../services/BillingStatus';
import PaymentDocumentType from '../services/PaymentDocumentType';

const UNKNOW_STATUS = "UNKNOW";


export function buildBillingFilters(userDataSession, where = {}) {
    where.STATUS_IN = [BillingStatus.PAID, BillingStatus.PENDING, BillingStatus.TO_CONFIRM];
    where.CREATED_AFTER_DATE = configVars.showOCDateAfter
    const userInfo = userDataSession;
    const user = userInfo.usuario || {};
    const userData = user.empresa_idempresa ? user : {};
    const invoiceCenter = userData.centro_facturacion || {};
    const invoiceCenterData = invoiceCenter || {};
    const subsidiary = userData.empresa || {};
    const subsidiaryData = subsidiary || {};

    if (AuthService.canDo(Permission.SEE_FULL_COMPANY_DATA)) {
        where.COMPANY =
            subsidiaryData.grupo_empresa_idgrupo_empresa || UNKNOW_STATUS;
    } else {
        where.INVOICE_CENTER_ID = [
            invoiceCenterData.idcentro_facturacion || UNKNOW_STATUS
        ];
    }
    where.DOCUMENT_KIND = PaymentDocumentType.billingValidTypes();
    where.DOCUMENT_KIND_PARTIAL = true;
    return { where: JSON.stringify(where) };
}

export function buildClosureFilters(userDataSession, where = {}){

    const userInfo = userDataSession;
    const user = userInfo.usuario || {};
    const userData = user.empresa_idempresa ? user : {};
    const invoiceCenter = userData.centro_facturacion || {};
    const invoiceCenterData = invoiceCenter || {};
    const subsidiary = userData.empresa || {};
    const subsidiaryData = subsidiary || {};

    if (AuthService.canDo(Permission.SEE_FULL_COMPANY_DATA)) {
      where.COMPANY =
        subsidiaryData.grupo_empresa_idgrupo_empresa || UNKNOW_STATUS;
    } else {
      where.INVOICE_CENTER_ID = [
        invoiceCenterData.idcentro_facturacion || UNKNOW_STATUS
      ];
    }
    return { where: JSON.stringify(where) };
}

export function buildSaleNoteFilters(userDataSession, where = {}) {
    where.STATUS_IN = [BillingStatus.PAID, BillingStatus.PENDING, BillingStatus.TO_CONFIRM];
    const userInfo = userDataSession;
    const user = userInfo.usuario || {};
    const userData = user.empresa_idempresa ? user : {};
    const invoiceCenter = userData.centro_facturacion || {};
    const invoiceCenterData = invoiceCenter || {};
    const subsidiary = userData.empresa || {};
    const subsidiaryData = subsidiary || {};

    if (AuthService.canDo(Permission.SEE_FULL_COMPANY_DATA)) {
      where.COMPANY =
        subsidiaryData.grupo_empresa_idgrupo_empresa || UNKNOW_STATUS;
    } else {
      where.INVOICE_CENTER_ID = [
        invoiceCenterData.idcentro_facturacion || UNKNOW_STATUS
      ];
    }
    where.DOCUMENT_KIND = [PaymentDocumentType.SALE_NOTE];

    return { where: JSON.stringify(where) };
  }