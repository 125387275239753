/**
 * Created by Ale on 10/3/2019.
 */
import React, {Component} from 'react';
import moment from 'moment';
import BackendApiService from '../../services/BackendApiService';
import { Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moneyFormat from '../../helpers/MoneyFormat';

class SeeOCInfo extends Component {
  constructor (props) {
    super(props);

    this.state = {
      finding: false,
      deletingOC: false,
      data: [],
      ocInDeleteProcess: null
    }
  }

  componentDidMount() {
    this.setState({finding: true});
    BackendApiService.findPurchaseOrdersByDoc(this.props.paymentDocId)
      .then(data => {
        this.setState({data, finding: false});
      })
      .catch(error => {
        console.error(error);
        this.setState({finding: false});
        alert('Ocurrió un error al buscar las OC');
      })
  }

  renderDelete = (ocId) => {
    return this.state.deletingOC && this.state.ocInDeleteProcess === ocId ?
      <div className="spinner-grow spinner-grow-sm"></div> :
      <button title="Eliminar OC" style={{all: 'unset', cursor: 'pointer'}} onClick={() => this.deleteOC(ocId)}>
        <FontAwesomeIcon icon="trash" color="#ff562e"/>
      </button>
  }
  renderUrl = (item) => {
    return item.oc_url ? <a href={item.oc_url} target="blank">Ver</a> : '';
  }

  deleteOC = async (ocId) => {
    alert('¿Está seguro que desea eliminar la OC?');
    let stateOCData = this.state.data;

    try {
      this.setState({deletingOC: true, ocInDeleteProcess: ocId});
      const response = await BackendApiService.deletePurchaseOrder(ocId);
      if (response.status === 'OK') {
        alert('Se eliminó la Orden de Compra satisfactoriamente');
        const ocIndex = stateOCData.findIndex(oc => oc.oc_id === ocId);
        stateOCData.splice(ocIndex, 1);
        return true;
      }
    } catch (e) {
      console.error(e);
      alert('Ocurrió un error eliminando la OC');
    } finally {
      this.setState({deletingOC: false, data: stateOCData});
    }
  }

  render () {
    const itemsMapped = this.state.data.map((item, index)=> {
      return <tr key={index}>
        <td>{item.oc_number}</td>
        <td>{moment.utc(item.oc_date).format('DD-MM-YYYY')}</td>
        <td>{`$${moneyFormat(item.oc_amount)}`}</td>
        <td>{this.renderUrl(item)}</td>
        <td className="text-center">{this.renderDelete(item.oc_id)}</td>
      </tr>
    });

    return (
      <Modal size="md" isOpen={true} centered={true} className="cobranza-modal draft">
        <ModalHeader className="bg-155892">
          <label className="header-label font-weight-bold m-0">Órdenes de compra</label>
          <button type="button" className="close" aria-label="Close" onClick={this.props.closeModalHandler}>
            <span aria-hidden="true">×</span>
          </button>
        </ModalHeader>

        <ModalBody>
          {
            this.state.finding ?
              <div className="text-center">
                <div className="spinner-grow" color="primary"></div>
              </div> :
              <div>
                <Row className="pt-3 pb-3">
                  <Col sm={12}>
                    <table className="table table-striped table-bordered">
                      <thead style={{color: "grey"}}>
                      <tr>
                        <td>Número</td>
                        <td>Fecha</td>
                        <td>Monto</td>
                        <td>Documento</td>
                        <td></td>
                      </tr>
                      </thead>
                      <tbody color="grey">
                      {itemsMapped}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </div>
          }
        </ModalBody>
      </Modal>
    )
  }
}

export default SeeOCInfo;