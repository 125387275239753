import React, { Component } from "react";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Alert } from "reactstrap";
import { payment } from "../../config"

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: props.userData,
      ButtonState: true,
      name: props.userData.afpr_us_data ? `${props.userData.afpr_us_data.nombre} ${props.userData.afpr_us_data.apellido_paterno ? props.userData.afpr_us_data.apellido_paterno : ''} ${props.userData.afpr_us_data.apellido_materno ? props.userData.afpr_us_data.apellido_materno : ''}` : '',
      email: props.userData.afpr_us_data ? props.userData.afpr_us_data.email : '',
      data: (props.location && props.location.state && props.location.state.data) || [],
      documentId: [],
      payment_method: payment.invoice.defaultPaymentType,
      total: 0,
      dataTable: [],
      alertVisible: true,
    }
  }

  componentWillMount() {
    const dataTable = [];
    const documentId = Object.keys(this.state.data);
    let total = 0;

    documentId.forEach((index) => {
      const auxTotal = this.state.data[index].total - this.state.data[index].total_pagado;
      total += auxTotal;
      dataTable.push(
        <tr key={index}>
          <td>{this.state.data[index].tipo_documento_pago}</td>
          <td>{this.state.data[index].identificador_tributario}</td>
          <td>{this.state.data[index].nombre}</td>
          <td>{this.state.data[index].nombre_corto}</td>
          <td>{moment(this.state.data[index].timestamp_emision).format('DD-MM-YYYY')}</td>
          <td>{moment(this.state.data[index].timestamp_end).format('DD-MM-YYYY')}</td>
          <td className="text-right">$ {auxTotal.toLocaleString()}</td>
        </tr>
      )
    });

    this.setState({
      dataTable,
      total,
      documentId,
    });
  }

  pay = async () => {
    this.setState({
      ButtonState: true,
    });
    const form = document.createElement('form');
    document.body.appendChild(form);
    form.method = 'POST';
    form.setAttribute('hidden', 'true');
    form.action = payment.invoice.paymentUrl;

    const paymentDocIdsInput = document.createElement('input');
    paymentDocIdsInput.name = 'paymentDocIds';
    paymentDocIdsInput.value = `[${Object.keys(this.state.data).toString()}]`;
    form.appendChild(paymentDocIdsInput);

    const userIdInput = document.createElement('input');
    userIdInput.name = 'userId';
    userIdInput.value = this.state.userData.afpr_us_user_id;
    form.appendChild(userIdInput);

    const paymentMethodInput = document.createElement('input');
    paymentMethodInput.name = 'payment_method';
    paymentMethodInput.value = this.state.payment_method;
    form.appendChild(paymentMethodInput);

    const successUrlInput = document.createElement('input');
    successUrlInput.name = 'success_url';
    successUrlInput.value = payment.invoice.successUrl;
    form.appendChild(successUrlInput);

    const failUrlInput = document.createElement('input');
    failUrlInput.name = 'fail_url';
    failUrlInput.value = payment.invoice.errorUrl;
    form.appendChild(failUrlInput);
    form.submit();
  }

  onChangePaymentMethod = (e) => {
    this.setState({
      payment_method: e.target.value,
      ButtonState: false,
    });
  }

  onDismissAlert = () => {
    this.setState({
      alertVisible: false,
    })
  }

  render() {

    //TODO call api to get payment methods
    const paymentMethodConfig = [
      {
        paymentId: payment.invoice.defaultPaymentType,
        imageUrl: "https://checkout.billing.autofactpro.com/images/khipu.png",
        altImage: "khipu",
        description: "Transferencia Khipu",
      }
    ];

    const paymentMethods = [];
    paymentMethodConfig.forEach(config => {
      paymentMethods.push(<MethodPaymentButton key={config.paymentId} {...config} onChange={this.onChangePaymentMethod} />)
    });

    return (
      <div>
        {this.state.userData.afpr_us_data ?
          this.state.documentId.length > 0 ? 
          (
            <div className="payment">
              <div className="page-title">
                <FontAwesomeIcon
                  icon="file-invoice"
                  className="icon-title"
                  alt="Icono de Facturas"
                />
                <h2 className="text-title">Pago de Documentos</h2>
              </div>
              <div className="main-container">
                <Alert color="warning" isOpen={this.state.alertVisible} toggle={this.onDismissAlert}>
                  <h4 className="alert-heading">Atención</h4>
                  <p>
                  IMPORTANTE! Si es el primer pago que vas a realizar con este medio de pago, debes considerar que existen restricciones bancarias al limite diario de transferencia.
                  </p>
                </Alert>
                <Alert color="danger" isOpen={this.state.total > payment.invoice.totalLimit} dismissible>
                  <h4 className="alert-heading">Atención</h4>
                  <p>
                    El monto total a pagar no puede ser superior a los ${payment.invoice.totalLimit.toLocaleString()}.
                  </p>
                </Alert>
                <h2 className="text-title">Resumen de los pagos a realizar</h2>
                <Table responsive className="payment-resume">
                  <thead>
                    <tr>
                      <th>Tipo de documento</th>
                      <th>Folio SII</th>
                      <th>Centro de facturación</th>
                      <th>Sucursal</th>
                      <th>Fecha de emisión</th>
                      <th>Fecha de vencimiento</th>
                      <th>Valor a pagar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.dataTable}
                  </tbody>
                  <tfoot>
                    <tr className="total">
                      <td className="text-right" colSpan="6"><b>Total a pagar</b></td>
                      <td className="text-right"><b>$ {this.state.total.toLocaleString()}</b></td>
                    </tr>
                  </tfoot>
                </Table>
                <div>
                  <h2 className="text-title">Información de pago</h2>
                  <p>Nombre: <b>{this.state.name}</b></p>
                  <p>Email: <b>{this.state.email}</b></p>
                </div>
                <div>
                  <h2 className="text-title">Seleccione el método de pago</h2>
                  <div className="payment-method">
                    {paymentMethods}
                  </div>
                </div>
                <div>
                  <p className="text">
                      <button className="btn btn-bg-orange-white pay"
                        onClick={this.pay} disabled={this.state.ButtonState || this.state.total > payment.invoice.totalLimit}>Pagar</button>
                  </p>
                </div>
              </div>
            </div>
          ) :
          (
            <Redirect to="/facturas" />
          )
          : (
            <Redirect to="/login" />
          )}
      </div>
    );
  }
}



class MethodPaymentButton extends Component {
  render() {
    return (
      <div className="method">
        <label>
          <input
            type="radio"
            className="paymentType"
            value={this.props.paymentId}
            onChange={this.props.onChange}
            defaultChecked={false}
          ></input>
          <div className="checkmark">
            <i className="fa fa-check" aria-hidden="true"></i>
          </div>
          <img src={this.props.imageUrl} alt={this.props.altImage} ></img>
          <p>{this.props.description}</p>
        </label>
      </div>)
  }
}

export default connect(store => ({
  userData: store.app.userData
}))(Payment);
