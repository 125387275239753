import React, {Component} from 'react';
import BackendApiService from "../../services/BackendApiService";
import {Button} from "reactstrap";
import moneyFormat from '../../helpers/MoneyFormat'
import excelIcon from '../../theme/assets/ico-excel.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BankTransactionsList from "../bank-transactions-list/BankTransactionsList";

class BankTransactionsListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bankTransactionsList:[],
            params: this.props.filters || {},
            transferenceSelected: {},
            fetchParams: ['where', 'per_page', 'page'],
            exportingSelectedExcel: false,
            exportingExcel: false,
            exportResult:'',
            exportSelectedResult:''
        };
        this.fetchData                        = this.fetchData.bind(this);
        this.download = this.download.bind(this);
        this.selectedRow = this.selectedRow.bind(this);

        this.bankTransactionsComponentRef = React.createRef();
    }

    async fetchData(params) {
        params = Object.assign(this.state.params, params);
        params = this.filterParams(params);
        let bankTransactionsList = []
        if(this.props.accountSelected){
            bankTransactionsList = await BackendApiService.getAccountTransactions(this.props.accountSelected.id, params);
            this.props.updateCount(`$${moneyFormat(this.props.accountSelected.countable_balance)}`);
        }else{
            this.props.updateCount(`$0`);
        }
        this.setState({params, bankTransactionsList});
        return bankTransactionsList;

    }
    selectedRow(e, item){
        const selecteds = Object.assign({}, this.state.transferenceSelected);
        if(e.target.checked){
            selecteds[item.id] = true;
        }else{
            delete selecteds[item.id];
        }
        this.setState({transferenceSelected: selecteds});
    }
    async exportData(format, from) {
        const params = this.state.params;
        params.format = format;
        params.from = from;
        if(params.from === 'selected'){
            params.IDS = Object.keys(this.state.transferenceSelected);
        }
        const exportResult = await BackendApiService.getAccountTransactions(this.props.accountSelected.id, params);
        return exportResult.data;
    }
    download(format, from) {
        const exportingContext = from === 'all'? 'exportingExcel':'exportingSelectedExcel';
        const exportingResultContext = from === 'all'? 'exportResult':'exportSelectedResult';
        this.setState({[exportingContext]: true})
        let link = document.createElement('a');
        link.target = '_blank';
        this.exportData(format, from)
            .then((response) => {
                this.setState({[exportingContext]: false, [exportingResultContext]: response.url});
            })
            .catch(() => {
                alert('Error exportando datos');
                this.setState({[exportingContext]: false});
            });
    }
    filterParams(params) {
        const filtered = Object.keys(params)
            .filter(key => this.state.fetchParams.includes(key))
            .reduce((obj, key) => {
                obj[key] = params[key];
                return obj;
            }, {});
        return filtered;
    }

    refreshFilters(filters) {
        this.setState({params: filters});
        setTimeout(() => {
            if(this.bankTransactionsComponentRef.current)
             this.bankTransactionsComponentRef.current.reloadData();
        }, 100);
    }

    renderExportSelectedButton() {
        const style = {
            borderRadius: '5px',
            fontFamily: 'Roboto',
            fontSize: '14px'
        }
        return this.state.exportSelectedResult ? <a
            key="export-xls-download"
            href={this.state.exportSelectedResult}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => this.setState({exportSelectedResult: ''})}
            className={'btn donwload-button btn-sm actions'}
            disabled={this.state.exportingSelectedExcel}>
            <FontAwesomeIcon icon="file-download"/>
            Descargar Excel
        </a> : <Button
            className="btn btn-sm btn-outline-primary-darker"
            style={style}
            onClick={(e)=>this.download('xlsx', 'selected')}
            disabled={Object.keys(this.state.transferenceSelected).length === 0}
        >
            <img className="excelIcon" src={excelIcon} alt="Excel icon"/>&nbsp;&nbsp;
            {this.state.exportingSelectedExcel ? "Exportando..." : "Exportar seleccionados a Excel"}
        </Button>

    }
    renderExportFullButton() {
        const style = {
            borderRadius: '5px',
            fontFamily: 'Roboto',
            fontSize: '14px'
        }
        return this.state.exportResult ? <a
            key="export-xls-download"
            href={this.state.exportResult}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => this.setState({exportResult: ''})}
            className={'btn donwload-button btn-sm actions'}
            disabled={this.state.exportingExcel}>
            <FontAwesomeIcon icon="file-download"/>
            Descargar Excel
        </a> : <Button
            className="btn btn-sm btn-outline-primary-darker"
            style={style}
            onClick={(e)=>this.download('xlsx', 'all')}
        >
            <img className="excelIcon" src={excelIcon} alt="Excel icon"/>&nbsp;&nbsp;
            {this.state.exportingExcel ? "Exportando..." : "Exportar todos a Excel"}
        </Button>

    }
    componentDidUpdate(prevProps){
        if (this.props.accountSelected && (!prevProps.accountSelected || (this.props.accountSelected.id !== prevProps.accountSelected.id))) {
            if(this.bankTransactionsComponentRef.current)
                this.bankTransactionsComponentRef.current.reloadData();
        }
    }
    render() {
        let component = null;
        let includeExportComponent = false;
        if(this.props.loadedAccountsError){
            component = <span>{this.props.loadedAccountsError}</span>;
        }else if(this.props.loadedAccounts){
            includeExportComponent = true;
            component = <BankTransactionsList
                ref={this.bankTransactionsComponentRef}
                id={this.props.id}
                accountSelected={this.props.accountSelected}
                fetchDataCallback={this.fetchData}
                selectedRow={this.selectedRow}
                selecteds={this.state.transferenceSelected}
            />;
        }else{
            component = <span>Cargando...</span>
        }
        return (
            <div>
                <div>
                    {component}
                </div>
                {includeExportComponent && <div className="col d-flex justify-content-between">
                    {this.renderExportSelectedButton()}
                    {this.renderExportFullButton()}
                </div>}
            </div>
        );
    }
}

export default BankTransactionsListContainer;
