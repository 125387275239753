import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import AuthService from "../../services/AuthService";
import url from 'url'

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            logged: false,
            login: true,
            redirect: this.props.location.state && this.props.location.state.from ? this.props.location.state.from : '/'
        }
    }

    async componentDidMount() {
        this.setState({login: true});
        const loginResult = await AuthService.login();
        let logged = false;
        if (loginResult) {
            logged = true;
        }
        setTimeout(() => {
            this.setState({logged, login: false});
            this.props.setIsAuthenticated(logged);
        }, 2000);
    }

    render() {
        if (this.state.login) {
            return (<div>Login...</div>);
        }
        else {
            if (this.state.logged) {

                if(url.parse(this.props.location.search, true).query.updateContactData === 'true'){

                    const queryString = url.parse(this.props.location.search, true).query.showMessage === 'true' ? '?showMessage=true' : '';

                    return <Redirect to={`/contacto-facturacion${queryString}`}/>    
                }
                return <Redirect to={this.state.redirect}/>
            }
            else {
                return (<div>
                    Redirecting...
                    <Redirect to={'external-login'}/>
                </div>);
            }
        }
    }
}

export default Login;
